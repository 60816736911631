import { CreditTariff } from 'api/Credit/types';
import { updateCreditTariffRequest } from 'api/Credit/api';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { message, notification } from 'antd';
import { useState } from 'react';

const useUpdateCreditTariff = (onCreditTariffUpdated: (creditTariff: CreditTariff) => void) => {
  const [ isUpdating, setIsUpdating ] = useState<boolean>(false);

  const updateCreditTariff = (creditTariffId: string, price: number) => {
    setIsUpdating(true);

    updateCreditTariffRequest(creditTariffId, price)
      .then(({ data }) => {
        message.success('Tariff plan was updated');
        onCreditTariffUpdated(data);
      })
      .catch((reason) => {
        notification.error({ duration: 0, type: 'error', message: getErrorFromPromiseReason(reason) });
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  return { isUpdating, updateCreditTariff };
};

export default useUpdateCreditTariff;
