import { downloadResultsRequest } from 'api/Data/api';
import { Order, Status } from 'api/Exec/types';
import { Role } from 'api/Users/types';
import { dashboardUrl, filesUrl } from 'app/consts/urls';
import { formatDateTime } from 'app/helpers/dateTime';
import { getFileFromAxiosBlobResponse } from 'app/helpers/downloadFile';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';
import { canCancelOrder, formatDuration, getOrderStatusColor } from 'components/Dashboard/Orders/helpers';
import { RerunFile, UpdatedOrder } from 'components/Dashboard/Orders/Orders';
import { useAppSelector } from 'store/hooks';
import { selectMyUser } from 'store/users/usersSlice';

import React, { useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { ItemType } from 'antd/es/menu/interface';
import { Button, Dropdown, notification, Space, Tag, Tooltip, Typography } from 'antd';
import { DownloadOutlined, EllipsisOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const useOrderColumns = (
  onRerunOrder: (file: RerunFile) => void,
  onCancelOrder: (orderId: string) => void,
  onUpdateOrder: (order: UpdatedOrder) => void,
  onRegeneratePdf: (orderId: string) => void,
  onPdfReportClick: (orderId: string) => void,
) => {
  const navigate = useNavigate();
  const goToFiles = (file: string) => navigate(`/${dashboardUrl}/${filesUrl}?search=${file}`, { replace: true });

  const { role: myUserRole } = useAppSelector(selectMyUser);
  const [ loadingOrderId, setLoadingOrderId ] = useState<string | null>(null);

  const downloadResults = (orderId: string, resultType: string, label: string) => {
    if (label === 'View PDF reports') {
      onPdfReportClick(orderId);
    } else {
      setLoadingOrderId(orderId);

      downloadResultsRequest(orderId, resultType)
        .then((response) => {
          getFileFromAxiosBlobResponse(response);
        })
        .catch((reason) => {
          notification.error({ duration: 0, type: 'error', message: getErrorFromPromiseReason(reason) });
        })
        .finally(() => {
          setLoadingOrderId(null);
        });
    }
  };

  const columns: ColumnsType<Order> = [
    {
      title: 'Order id',
      dataIndex: 'id',
      render: (_, { id, srcFileShortPath, srcFileName, srcFileId, virtual }) => (
        <>
          <Typography.Text style={{ whiteSpace: 'nowrap' }}>{id}</Typography.Text>
          <br />
          <Tooltip title={srcFileShortPath} styles={{ root: { maxWidth: 500 } }}>
            {virtual ? (
              <Typography.Text ellipsis style={{ fontSize: 12, whiteSpace: 'nowrap', maxWidth: 280 }}>
                {srcFileName}
              </Typography.Text>
            ) : (
              <Typography.Link ellipsis onClick={() => goToFiles(srcFileId)} style={{ fontSize: 12, whiteSpace: 'nowrap', maxWidth: 280 }}>
                {srcFileName}
              </Typography.Link>
            )}
          </Tooltip>
        </>
      ),
    },
    {
      title: 'Submitted by',
      render: (_, { submittedBy, orgName }) => (
        <>
          {submittedBy}
          {myUserRole === Role.SYSTEM_ADMIN && (
            <>
              <br />
              <Typography.Text type="secondary" style={{ fontSize: 12 }}>{orgName}</Typography.Text>
            </>
          )}
        </>
      ),
    },
    {
      title: 'Started',
      render: (_, { startedAt }) => <Typography.Text style={{ whiteSpace: 'nowrap' }}>{formatDateTime(startedAt)}</Typography.Text>,
    },
    {
      title: 'Type',
      render: (_, { virtual, type }) => {
        return virtual ? 'EXTERNAL' : type;
      },
    },
    {
      align: 'center',
      title: 'Samples',
      dataIndex: 'amountOfSamples',
    },
    {
      title: 'Model',
      dataIndex: 'model',
    },
    {
      title: 'Duration',
      render: (_, { executionTime }) => formatDuration(executionTime),
    },
    {
      title: 'Tags',
      render: (_, { tags }) => (
        <Space direction="vertical" size="small">{tags.map(t => <Tag key={t.id} color="default">{t.name}</Tag>)}</Space>
      ),
    },
    {
      title: 'Status',
      render: (_, { status }) => {
        const color = getOrderStatusColor(status);
        return <Tag color={color} key={status} style={{ marginRight: 0, maxWidth: 180 }}>{status}</Tag>;
      },
    },
    {
      align: 'center',
      title: 'Results',
      key: 'results',
      render: (_, { id, resultTypes }) => {
        if (resultTypes && resultTypes.length > 0) {
          const items: ItemType[] = resultTypes.map(({ type, label }) => {
            return { key: label, label, onClick: () => downloadResults(id, type, label) };
          });

          return (
            <Tooltip title="Download results">
              <div> { /* We need this div to avoid findDOMNode is deprecated in StrictMode error */ }
                <Dropdown key={id} trigger={[ 'click' ]} menu={{ items }}>
                  <Button
                    data-testid="download-results-button"
                    style={{ padding: 0 }}
                    type="link"
                    icon={<DownloadOutlined />}
                    loading={loadingOrderId === id}
                  />
                </Dropdown>
              </div>
            </Tooltip>
          );
        }
      },
    },
    {
      align: 'center',
      title: 'Actions',
      key: 'actions',
      render: (_, { id, srcFileId, status, tags, canRequestPdf, virtual }) => {
        const tagIds = tags.map(t => t.id);
        const updatedOrder: UpdatedOrder = { id, tagIds };
        const items: ItemType[] = [ { key: 'update', label: 'Update tags', onClick: () => onUpdateOrder(updatedOrder) } ];

        if (status === Status.FAILED && !virtual) {
          const file: RerunFile = { id: srcFileId, tagIds };
          items.unshift({ key: 'rerun', label: 'Rerun', onClick: () => onRerunOrder(file) });
        }

        if ((status === Status.COMPLETED || status === Status.REPORTS_FAILED || status === Status.MAKING_REPORT) && canRequestPdf) {
          items.unshift({ key: 'regenerate', label: 'Regenerate PDF', onClick: () => onRegeneratePdf(id) });
        }

        if (canCancelOrder(status)) {
          items.unshift({ key: 'cancel', label: 'Cancel', onClick: () => onCancelOrder(id) });
        }

        return (
          <Dropdown key={id} trigger={[ 'click' ]} menu={{ items }}>
            <EllipsisOutlined style={{ fontSize: 24 }} />
          </Dropdown>
        );
      },
    },
  ];

  return { columns };
};

export default useOrderColumns;
