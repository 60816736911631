import { CreditExchange } from 'api/Credit/types';
import { createCreditExchangeRequest } from 'api/Credit/api';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { message, notification } from 'antd';
import { useState } from 'react';

const useAddCreditExchange = (onCreditExchangeAdded: (creditExchange: CreditExchange) => void) => {
  const [ isCreating, setIsCreating ] = useState<boolean>(false);

  const addCreditExchange = (orgId: string, price: number, rate: number) => {
    setIsCreating(true);

    createCreditExchangeRequest(orgId, price, rate)
      .then(({ data }) => {
        message.success('Exchange was added');
        onCreditExchangeAdded(data);
      })
      .catch((reason) => {
        notification.error({ duration: 0, type: 'error', message: getErrorFromPromiseReason(reason) });
      })
      .finally(() => {
        setIsCreating(false);
      });
  };

  return { isCreating, addCreditExchange };
};

export default useAddCreditExchange;
