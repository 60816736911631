import { Org } from 'api/Orgs/types';
import TableTopPanel from 'app/common_components/TableTopPanel/TableTopPanel';
import 'components/Dashboard/Orgs/Orgs.css';
import usePaginationParams from 'app/hooks/usePaginationParams';
import useTablePagination from 'app/hooks/useTablePagination';
import { DashboardPageProps } from 'components/Dashboard/types';
import { ORG_ID_NEW } from 'components/Dashboard/Orgs/consts';
import { useColumns } from 'components/Dashboard/Orgs/hooks';
import { selectOrgs } from 'store/orgs/orgsSlice';
import { useAppSelector } from 'store/hooks';

import { PlusOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Orgs: React.FC<DashboardPageProps> = ({ setTitle }) => {
  const initOrgs = useAppSelector(selectOrgs);
  const [ orgs, setOrgs ] = useState<Org[]>([]);
  const { columns, goToEditOrg } = useColumns();
  const { searchParam } = usePaginationParams();
  const navigate = useNavigate();
  const goToCreateOrg = () => navigate(ORG_ID_NEW);

  useEffect(() => {
    if (searchParam) {
      setOrgs(initOrgs.filter((org: Org) => org.name.toLowerCase().includes(searchParam.toLowerCase())));
    } else {
      setOrgs(initOrgs);
    }
  }, [ searchParam, initOrgs ]);

  const { tablePagination } = useTablePagination();
  const pagination = tablePagination(orgs.length);

  useEffect(() => {
    setTitle('Organizations');
  }, [ setTitle ]);

  return (
    <>
      <TableTopPanel
        RightColumn={<Button type="primary" onClick={goToCreateOrg} icon={<PlusOutlined />}>Add organization</Button>}
        placeholder="Search ogranizations"
      />

      <Table
        rowKey={(org: Org) => org.id}
        columns={columns}
        dataSource={orgs}
        scroll={{ x: true }}
        pagination={pagination}
        onRow={record => ({
          onClick: () => goToEditOrg(record.id),
        })}
        rowClassName="orgRow"
      />
    </>
  );
};

export default Orgs;
