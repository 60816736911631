import { addNamedModelRequest } from 'api/Exec/api';
import { NamedModel } from 'api/Exec/types';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { message, notification } from 'antd';
import { useState } from 'react';

const useAddNamedModel = (onNamedModelAdded: (namedModel: NamedModel) => void) => {
  const [ isCreating, setIsCreating ] = useState<boolean>(false);

  const addNamedModel = (modelId: string, modelName: string) => {
    setIsCreating(true);

    addNamedModelRequest(modelId, modelName)
      .then(({ data }) => {
        message.success('Named model was added');
        onNamedModelAdded(data);
      })
      .catch((reason) => {
        notification.error({ duration: 0, type: 'error', message: getErrorFromPromiseReason(reason) });
      })
      .finally(() => {
        setIsCreating(false);
      });
  };

  return { isCreating, addNamedModel };
};

export default useAddNamedModel;
