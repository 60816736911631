import { resendInviteRequest } from 'api/Users/api';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { useState } from 'react';
import { message, notification } from 'antd';

const useResendInvite = (finalCallback: () => void) => {
  const [ isResending, setIsResending ] = useState<boolean>(false);

  const resendInvite = (userId: string) => {
    setIsResending(true);
    resendInviteRequest(userId)
      .then(() => {
        message.success('Invitation was resent');
      })
      .catch((reason) => {
        notification.error({ duration: 0, type: 'error', message: getErrorFromPromiseReason(reason) });
      })
      .finally(() => {
        setIsResending(false);
        finalCallback();
      });
  };

  return { isResending, resendInvite };
};

export default useResendInvite;
