import { createCreditTariffRequest } from 'api/Credit/api';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { message, notification } from 'antd';
import { useState } from 'react';

const useAddCreditTariff = () => {
  const [ isCreating, setIsCreating ] = useState<boolean>(false);

  const addCreditTariff = async (orgId: string, creditServiceId: string, price: number) => {
    setIsCreating(true);

    return createCreditTariffRequest(orgId, creditServiceId, price)
      .then(({ data }) => {
        message.success('Tariff plan was added');
        return data;
      })
      .catch((reason) => {
        notification.error({ duration: 0, type: 'error', message: getErrorFromPromiseReason(reason) });
      })
      .finally(() => {
        setIsCreating(false);
      });
  };

  return { isCreating, addCreditTariff };
};

export default useAddCreditTariff;
