import { axiosInstance } from 'api/instance';
import { Org, OrgModel, CreateUpdateOrgData, SftpUser, Webhook } from 'api/Orgs/types';
import urls from 'api/Orgs/urls';

import type { TransferProps } from 'antd';
import { AxiosResponse } from 'axios';

export const getOrgsRequest = ({ signal }: AbortController): Promise<AxiosResponse<Org[]>> => {
  return axiosInstance.get<Org[]>(urls.orgsUrl, { signal });
};

export const createOrgRequest = (data: CreateUpdateOrgData): Promise<AxiosResponse<Org>> => {
  return axiosInstance.post<Org>(urls.orgsUrl, data);
};

export const generateOrgApiKeyRequest = (orgId: string): Promise<AxiosResponse<string>> => {
  return axiosInstance.post<string>(urls.orgGenerateApiKeyUrl(orgId));
};

export const updateOrgRequest = (orgId: string, data: CreateUpdateOrgData): Promise<AxiosResponse<Org>> => {
  return axiosInstance.put<Org>(urls.orgItemUrl(orgId), data);
};

export const updateOrgModelsRequest = (orgId: string, modelNames: TransferProps['targetKeys']): Promise<AxiosResponse<OrgModel[]>> => {
  return axiosInstance.post<OrgModel[]>(urls.orgModelsUrl(orgId), { modelNames });
};

export const getOrgModelsRequest = (orgId: string, hideDeprecated = false, { signal }: AbortController): Promise<AxiosResponse<OrgModel[]>> => {
  return axiosInstance.get<OrgModel[]>(urls.orgModelsUrl(orgId, hideDeprecated), { signal });
};

export const getOrgSftpUsersRequest = (orgId: string, { signal }: AbortController): Promise<AxiosResponse<SftpUser[]>> => {
  return axiosInstance.get<SftpUser[]>(urls.orgSftpUsersUrl(orgId), { signal });
};

export const createOrgSftpUserRequest = (orgId: string, sshPublicBodyKey?: string): Promise<AxiosResponse<SftpUser>> => {
  return axiosInstance.post<SftpUser>(urls.orgSftpUsersUrl(orgId), { sshPublicBodyKey });
};

export const deleteOrgSftpUserRequest = (orgId: string, sftpUserId: string): Promise<AxiosResponse> => {
  return axiosInstance.delete(urls.orgSftpUserItemUrl(orgId, sftpUserId));
};

export const createOrgSftpUserSshKeyRequest = (orgId: string, sftpUserId: string, sshPublicBodyKey?: string): Promise<AxiosResponse<SftpUser>> => {
  return axiosInstance.post<SftpUser>(urls.orgSftpUserSshKeysUrl(orgId, sftpUserId), { sshPublicBodyKey });
};

export const deleteOrgSftpUserSshKeyRequest = (orgId: string, sftpUserId: string, sshKeyId: string): Promise<AxiosResponse<SftpUser>> => {
  return axiosInstance.delete<SftpUser>(urls.orgSftpUserSshKeyItemUrl(orgId, sftpUserId, sshKeyId));
};

export const getOrgWebhooksInfoRequest = (orgId: string, { signal }: AbortController): Promise<AxiosResponse<Webhook[]>> => {
  return axiosInstance.get<Webhook[]>(urls.orgWebhooksInfo(orgId), { signal });
};

export const updateOrgWebhooksInfoRequest = (orgId: string, webhooks: Webhook[]): Promise<AxiosResponse<Webhook[]>> => {
  return axiosInstance.post<Webhook[]>(urls.orgWebhooksInfo(orgId), webhooks);
};

export const generateOrgWebhooksSecretRequest = (orgId: string): Promise<AxiosResponse<string>> => {
  return axiosInstance.post<string>(urls.orgWebhooksGenerateSecret(orgId));
};
