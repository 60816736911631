import { SSHKey } from 'api/Orgs/types';
import { copy } from 'app/helpers/copy';
import AddSshKeyForm from 'components/Dashboard/Orgs/Wizard/SftpUsers/AddSshKeyForm/AddSshKeyForm';

import React from 'react';
import { Button, Tooltip, Typography, List, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

export interface Props {
  onAddSshKey: (sshKey?: string) => void;
  isAddSshKeyModalOpen: boolean
  setIsAddSshKeyModalOpen: (flag: boolean) => void;
  onDeleteSshKey: (sshKeyId: string) => void;
  sshKeyIdToRemove: string | null,
  setSshKeyIdToRemove: (sshKeyId: string | null) => void;
  sshKeys: SSHKey[];
  onCancel: () => void;
  isLoading: boolean;
}

const { Title, Text } = Typography;

const SshKeysList: React.FC<Props> = ({
  sshKeys,
  onCancel,
  onAddSshKey,
  isAddSshKeyModalOpen,
  setIsAddSshKeyModalOpen,
  onDeleteSshKey,
  sshKeyIdToRemove,
  setSshKeyIdToRemove,
  isLoading,
}) => {
  const openDeleteSshKeyModal = (sshKeyId: string) => setSshKeyIdToRemove(sshKeyId);
  const closeDeleteSshKeyModal = () => setSshKeyIdToRemove(null);

  const openAddSshKeyModal = () => setIsAddSshKeyModalOpen(true);
  const closeAddSshKeyModal = () => setIsAddSshKeyModalOpen(false);

  return (
    <>
      <div style={{ maxHeight: 400, overflow: 'auto', margin: '16px 0' }}>
        <List
          dataSource={sshKeys}
          renderItem={(item: SSHKey) => {
            const actions = [
              <Typography.Link onClick={() => openDeleteSshKeyModal(item.id)} key="delete">delete</Typography.Link>,
              <Typography.Link onClick={() => copy(item.sshKeyPublic, 'Public key was copied')} key="public">copy public key</Typography.Link>,
            ];
            if (item.sshKeyAutoGenerated) {
              actions.push(<Typography.Link onClick={() => copy(item.sshKeyPrivate, 'Private key was copied')} key="private">copy private key</Typography.Link>);
            }

            return (
              <List.Item actions={actions}>
                <Tooltip title={item.id} styles={{ root: { maxWidth: 350 } }}>
                  <Text ellipsis>{item.id}</Text>
                </Tooltip>
              </List.Item>
            );
          }}
        />
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button type="primary" icon={<PlusOutlined style={{ fontSize: 16 }} />} onClick={openAddSshKeyModal}>
          Add SSH key
        </Button>
        <Button onClick={onCancel}>
          Cancel
        </Button>
      </div>

      <Modal
        title={<Title level={4}>Delete SSH key</Title>}
        open={!!sshKeyIdToRemove}
        width={420}
        onCancel={closeDeleteSshKeyModal}
        onOk={() => onDeleteSshKey(sshKeyIdToRemove!)}
        confirmLoading={isLoading}
        destroyOnClose
      >
        <div style={{ marginTop: 24 }}>
          <Typography.Text>Are you sure you want to delete this SSH key?</Typography.Text>
        </div>
      </Modal>

      <Modal
        title={<Title level={4}>Add SSH key</Title>}
        open={isAddSshKeyModalOpen}
        footer={null}
        width={420}
        onCancel={closeAddSshKeyModal}
        destroyOnClose
        data-testid="add-ssh-key-modal"
      >
        <AddSshKeyForm
          onSubmit={onAddSshKey}
          onCancel={closeAddSshKeyModal}
          isLoading={isLoading}
        />
      </Modal>
    </>
  );
};

export default SshKeysList;
