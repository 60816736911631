import { File, PaginatedFiles, FilesFilterParams } from 'api/Data/types';
import { Order } from 'api/Exec/types';
import TableTopPanel from 'app/common_components/TableTopPanel/TableTopPanel';
import usePaginationParams from 'app/hooks/usePaginationParams';
import useTablePagination from 'app/hooks/useTablePagination';
import { DashboardPageProps } from 'components/Dashboard/types';
import { useColumns, useDeleteFile, useFiles } from 'components/Dashboard/Files/hooks';
import AddFileForm from 'components/Dashboard/Files/AddFileForm/AddFileForm';
import CreateAncestryCallForm from 'components/Dashboard/Files/CreateAncestryCallForm/CreateAncestryCallForm';
import FilesFilter from 'components/Dashboard/Files/FilesFilter/FilesFilter';

import React, { useCallback, useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, message, Modal, Table, Typography } from 'antd';
import { DateTime } from 'luxon';

const { Title, Text } = Typography;

const Files: React.FC<DashboardPageProps> = ({ setTitle }) => {
  const { pageParam, pageSizeParam, searchParam, orgParam, minDateParam, maxDateParam, typeParam } = usePaginationParams();
  const { tablePagination } = useTablePagination();

  const [ isAddFileModalOpen, setIsAddFileModalOpen ] = useState<boolean>(false);
  const openAddFileModal = () => setIsAddFileModalOpen(true);
  const closeAddFileModal = () => setIsAddFileModalOpen(false);

  const [ createAncestryCallModalFileId, setCreateAncestryCallModalFileId ] = useState<string | null>(null);
  const createAncestryCall = (fileId: string) => setCreateAncestryCallModalFileId(fileId);
  const closeCreateAncestryCallModal = () => setCreateAncestryCallModalFileId(null);

  const [ fileIdToDelete, setFileIdToDelete ] = useState<string | null>(null);
  const openDeleteFileModal = (fileId: string) => setFileIdToDelete(fileId);
  const closeDeleteFileModal = () => setFileIdToDelete(null);

  const { columns } = useColumns(createAncestryCall, openDeleteFileModal);
  const { response, isLoading, fetchFiles, setResponse } = useFiles();

  const onOrderCreated = (order: Order) => {
    const fileIndex = response.results.findIndex((file: File) => file.id === order.srcFileId);

    setResponse({ results: [
      ...response.results.slice(0, fileIndex),
      { ...response.results[fileIndex], ordersCount: response.results[fileIndex].ordersCount + 1 },
      ...response.results.slice(fileIndex + 1, response.results.length),
    ],
    count: response.count });

    closeCreateAncestryCallModal();
    message.success('Ancestry call was created');
  };

  const onFileUploaded = (file: File) => {
    setResponse((prevState: PaginatedFiles) => {
      if (prevState.results.length === pagination.pageSize) {
        prevState.results.pop();
      }
      prevState.results.unshift(file);

      return { count: prevState.count + 1, results: [ ...prevState.results ] };
    });
    closeAddFileModal();
    message.success('File was uploaded');
  };

  const onFileDeleted = (fileId: string) => {
    const fileIndex = response.results.findIndex((file: File) => file.id === fileId);

    setResponse({ results: [
      ...response.results.slice(0, fileIndex),
      { ...response.results[fileIndex], deletedAt: DateTime.now().toString() },
      ...response.results.slice(fileIndex + 1, response.results.length),
    ],
    count: response.count });
    message.success('File was deleted');
  };

  const getFilter = useCallback((): FilesFilterParams => ({
    file: searchParam,
    onlyAcceptable: false,
    orgs: orgParam.split(','),
    minDate: minDateParam,
    maxDate: maxDateParam,
    showVirtual: !!typeParam,
  }), [ searchParam, orgParam, minDateParam, maxDateParam, typeParam ]);

  const reload = () => fetchFiles(pageParam, pageSizeParam, getFilter());

  const { deleteFile, isDeleting } = useDeleteFile(onFileDeleted, closeDeleteFileModal);

  useEffect(() => {
    setTitle('Files');
  }, [ setTitle ]);

  useEffect(() => {
    const abortController = new AbortController();
    fetchFiles(pageParam, pageSizeParam, getFilter(), abortController);

    return () => {
      abortController.abort();
    };
  }, [ fetchFiles, pageParam, pageSizeParam, getFilter ]);

  const pagination = tablePagination(response.count);

  return (
    <>
      <TableTopPanel
        RightColumn={<Button type="primary" onClick={openAddFileModal} icon={<PlusOutlined />}>Add file</Button>}
        placeholder="Search files"
        onReload={reload}
        Filter={FilesFilter}
      />

      <Table
        rowKey={(file: File) => file.id}
        columns={columns}
        dataSource={response.results}
        pagination={pagination}
        loading={isLoading}
        scroll={{ x: true }}
      />

      <Modal
        title={<Title level={4}>Add file</Title>}
        open={isAddFileModalOpen}
        footer={null}
        width={420}
        onCancel={closeAddFileModal}
        destroyOnClose
        data-testid="add-file-modal"
      >
        <AddFileForm onCancel={closeAddFileModal} onFileUploaded={onFileUploaded} />
      </Modal>

      <Modal
        title={<Title level={4}>Delete file</Title>}
        open={!!fileIdToDelete}
        width={420}
        onCancel={closeDeleteFileModal}
        onOk={() => deleteFile(fileIdToDelete!)}
        confirmLoading={isDeleting}
        destroyOnClose
      >
        <div style={{ marginTop: 24 }}>
          <Text>Are you sure you want to delete this file?</Text>
        </div>
      </Modal>

      <Modal
        title={<Title level={4}>Create Ancestry Call</Title>}
        open={!!createAncestryCallModalFileId}
        footer={null}
        width={640}
        onCancel={closeCreateAncestryCallModal}
        destroyOnClose
        data-testid="create-ancestry-call-modal"
      >
        {createAncestryCallModalFileId && (
          <CreateAncestryCallForm
            fileId={createAncestryCallModalFileId}
            onCancel={closeCreateAncestryCallModal}
            onOrderCreated={onOrderCreated}
          />
        )}
      </Modal>
    </>
  );
};

export default Files;
