import { ModelApiName } from 'api/Exec/types';
import TagsSelect from 'app/common_components/TagsSelect/TagsSelect';
import { MAX_FILE_SIZE_TO_UPLOAD } from 'app/consts/files';
import { useUploadResults } from 'components/Dashboard/Plots/LabelTable/hooks';

import { Button, Form, Space, Upload } from 'antd';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { UploadOutlined } from '@ant-design/icons';
import React, { useState } from 'react';

interface FormValues {
  fileType: string;
  file: UploadFile[];
  tagIds: string[];
}

interface Props {
  modelApiName: ModelApiName;
  onCancel: () => void;
}

const UploadResultsForm: React.FC<Props> = ({ onCancel, modelApiName }) => {
  const [ form ] = Form.useForm<FormValues>();
  const [ fileList, setFileList ] = useState<UploadFile[]>([]);
  const [ isFileSizeAcceptable, setIsFileSizeAcceptable ] = useState<boolean>(false);

  const { isUploading, uploadResults } = useUploadResults(onCancel);

  const props: UploadProps = {
    onRemove: () => {
      setFileList([]);
    },
    beforeUpload: (file) => {
      setIsFileSizeAcceptable(file.size <= MAX_FILE_SIZE_TO_UPLOAD);

      return false;
    },
    accept: '.tsv, .csv',
    maxCount: 1,
    fileList,
  };

  const onFinish = async (values: FormValues) => {
    if (values.file[0].originFileObj) {
      uploadResults(values.file[0].originFileObj, modelApiName, values.tagIds);
    }
  };

  const normalizeFile = (e: { file: RcFile, fileList: UploadFile[] }) => e.fileList;

  return (
    <Form
      form={form}
      name="uploadResultsForm"
      layout="vertical"
      requiredMark={false}
      onFinish={onFinish}
      style={{ marginTop: 24 }}
      initialValues={{ tagIds: [] }}
    >
      <Form.Item
        label="Results file"
        tooltip={{
          title: 'Only summary chromosomes results are supported',
          styles: { root: { maxWidth: 500 } },
        }}
        name="file"
        valuePropName="fileList"
        getValueFromEvent={normalizeFile}
        rules={[ { required: true, message: 'File is mandatory' },
          () => ({
            validator(_, value) {
              if (value && value.length && !isFileSizeAcceptable) {
                return Promise.reject(new Error('Max file size is 50 Mbs'));
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        <Upload {...props} disabled={isUploading} data-testid="upload-results">
          <Button icon={<UploadOutlined />} disabled={isUploading}>Select file</Button>
        </Upload>
      </Form.Item>

      <Form.Item name="tagIds" label="Tags">
        <TagsSelect disabled={isUploading} />
      </Form.Item>

      <Form.Item style={{ textAlign: 'right', marginBottom: 0 }}>
        <Space>
          <Button onClick={onCancel}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" loading={isUploading}>
            Upload
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default UploadResultsForm;
