import { getOrgWebhooksInfoRequest, updateOrgWebhooksInfoRequest } from 'api/Orgs/api';
import { Webhook } from 'api/Orgs/types';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { message, notification } from 'antd';
import { useCallback, useState } from 'react';

const useOrgWebhooks = () => {
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ webhooks, setWebhooks ] = useState<Webhook[]>([]);

  const fetchWebhooks = useCallback((orgId: string, abortController: AbortController) => {
    setIsLoading(true);
    getOrgWebhooksInfoRequest(orgId, abortController)
      .then((response) => {
        setWebhooks(response.data);
      })
      .catch((reason) => {
        if (!abortController?.signal.aborted) {
          message.error(getErrorFromPromiseReason(reason));
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const updateWebhooks = useCallback((orgId: string, data: Webhook[]) => {
    setIsLoading(true);
    updateOrgWebhooksInfoRequest(orgId, data)
      .then((response) => {
        setWebhooks(response.data);
        message.success('Webhooks were updated');
      })
      .catch((reason) => {
        notification.error({ duration: 0, type: 'error', message: getErrorFromPromiseReason(reason) });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return { webhooks, isLoading, fetchWebhooks, updateWebhooks };
};

export default useOrgWebhooks;
