import { generateOrgApiKeyRequest } from 'api/Orgs/api';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { notification } from 'antd';
import { useState } from 'react';

const useCreateApiUser = () => {
  const [ isApiKeyGenerating, setIsApiKeyGenerating ] = useState<boolean>(false);
  const [ apiKey, setApiKey ] = useState<string>('');

  const generateApiKey = (orgId: string) => {
    setIsApiKeyGenerating(true);

    generateOrgApiKeyRequest(orgId)
      .then((response) => {
        setApiKey(response.data);
      })
      .catch((reason) => {
        notification.error({ duration: 0, type: 'error', message: getErrorFromPromiseReason(reason) });
      })
      .finally(() => {
        setIsApiKeyGenerating(false);
      });
  };

  return { apiKey, isApiKeyGenerating, setApiKey, generateApiKey };
};

export default useCreateApiUser;
