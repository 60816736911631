import {
  createOrgSftpUserRequest,
  createOrgSftpUserSshKeyRequest,
  deleteOrgSftpUserRequest,
  deleteOrgSftpUserSshKeyRequest,
  getOrgSftpUsersRequest,
} from 'api/Orgs/api';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';
import { SftpUser } from 'api/Orgs/types';
import { changeOrgSftpUsersCount } from 'store/orgs/orgsSlice';
import { useAppDispatch } from 'store/hooks';

import { message, notification } from 'antd';
import { useCallback, useState } from 'react';

const useSftpUsers = () => {
  const dispatch = useAppDispatch();
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ sftpUsers, setSftpUsers ] = useState<SftpUser[]>([]);

  const fetchSftpUsers = useCallback((orgId: string, abortController: AbortController) => {
    setIsLoading(true);

    getOrgSftpUsersRequest(orgId, abortController)
      .then(({ data }) => {
        setSftpUsers(data);
      })
      .catch((reason) => {
        if (!abortController.signal.aborted) {
          notification.error({ duration: 0, type: 'error', message: getErrorFromPromiseReason(reason) });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const deleteSftpUser = async (orgId: string, sftpUserId: string) => {
    setIsLoading(true);

    return deleteOrgSftpUserRequest(orgId, sftpUserId)
      .then(() => {
        const sftpUserIndex = sftpUsers.findIndex(sftpUser => sftpUser.id === sftpUserId);
        setSftpUsers([ ...sftpUsers.slice(0, sftpUserIndex), ...sftpUsers.slice(sftpUserIndex + 1, sftpUsers.length) ]);
        dispatch(changeOrgSftpUsersCount({ orgId, value: -1 }));
        message.success('SFTP user was deleted');
      })
      .catch((reason) => {
        notification.error({ duration: 0, type: 'error', message: getErrorFromPromiseReason(reason) });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const createSftpUser = async (orgId: string, sshKey?: string) => {
    setIsLoading(true);

    return createOrgSftpUserRequest(orgId, sshKey)
      .then(({ data }) => {
        setSftpUsers([ ...sftpUsers, data ]);
        dispatch(changeOrgSftpUsersCount({ orgId, value: 1 }));
        message.success('SFTP user was created');
      })
      .catch((reason) => {
        notification.error({ duration: 0, type: 'error', message: getErrorFromPromiseReason(reason) });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const createSftpUserSshKey = async (orgId: string, sftpUserId: string, sshKey?: string) => {
    setIsLoading(true);

    return createOrgSftpUserSshKeyRequest(orgId, sftpUserId, sshKey)
      .then(({ data }) => {
        const sftpUserIndex = sftpUsers.findIndex(sftpUser => sftpUser.id === sftpUserId);
        setSftpUsers([ ...sftpUsers.slice(0, sftpUserIndex), data, ...sftpUsers.slice(sftpUserIndex + 1, sftpUsers.length) ]);
        message.success('SSH key was added');

        return data;
      })
      .catch((reason) => {
        notification.error({ duration: 0, type: 'error', message: getErrorFromPromiseReason(reason) });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteSftpUserSshKey = async (orgId: string, sftpUserId: string, sshKeyId: string) => {
    setIsLoading(true);

    return deleteOrgSftpUserSshKeyRequest(orgId, sftpUserId, sshKeyId)
      .then(({ data }) => {
        const sftpUserIndex = sftpUsers.findIndex(sftpUser => sftpUser.id === sftpUserId);
        setSftpUsers([ ...sftpUsers.slice(0, sftpUserIndex), data, ...sftpUsers.slice(sftpUserIndex + 1, sftpUsers.length) ]);
        message.success('SSH key was deleted');

        return data;
      })
      .catch((reason) => {
        notification.error({ duration: 0, type: 'error', message: getErrorFromPromiseReason(reason) });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return { sftpUsers, isLoading, fetchSftpUsers, deleteSftpUser, createSftpUser, createSftpUserSshKey, deleteSftpUserSshKey };
};

export default useSftpUsers;
