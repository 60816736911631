import { GroupStatus, Status } from 'api/Exec/types';
import { CreditServiceType } from 'api/Credit/types';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'app/consts/pagination';
import { FilterParams } from 'app/types/Filters';
import { SearchParams } from 'app/types/PaginationParams';

import { useSearchParams } from 'react-router-dom';
import { useCallback } from 'react';

const usePaginationParams = (defaultPage = DEFAULT_PAGE, defaultPageSize = DEFAULT_PAGE_SIZE) => {
  const [ searchParams, setSearchParams ] = useSearchParams();

  const pageParam = Number(searchParams.get(SearchParams.PAGE)) || defaultPage;
  const pageSizeParam = Number(searchParams.get(SearchParams.PAGE_SIZE)) || defaultPageSize;
  const searchParam = searchParams.get(SearchParams.SEARCH) || '';
  const orgParam = searchParams.get(SearchParams.ORG) || '';
  const orderStatusParam = searchParams.get(SearchParams.ORDER_STATUS) as any as Status || Status.EMPTY;
  const orderGroupStatusParam = searchParams.get(SearchParams.ORDER_GROUP_STATUS) as any as GroupStatus || GroupStatus.EMPTY;
  const tagParam = searchParams.get(SearchParams.TAG) || '';
  const modelParam = searchParams.get(SearchParams.MODEL) || '';
  const modelApiParam = searchParams.get(SearchParams.MODEL_API) || '';
  const minDateParam = searchParams.get(SearchParams.MIN_DATE) || '';
  const maxDateParam = searchParams.get(SearchParams.MAX_DATE) || '';
  const creditServiceTypeParam = searchParams.get(SearchParams.CREDIT_SERVICE_TYPE) as any as CreditServiceType || '';
  const fullLedgerParam = searchParams.get(SearchParams.FULL_LEDGER) || '';
  const typeParam = searchParams.get(SearchParams.TYPE) || '';

  const setPaginationParams = useCallback((page: number, pageSize: number) => {
    searchParams.set(SearchParams.PAGE, String(page));
    searchParams.set(SearchParams.PAGE_SIZE, String(pageSize));
    setSearchParams(searchParams, { replace: true });
  }, [ searchParams, setSearchParams ]);

  const setSearchParam = useCallback((value: string) => {
    if (value !== '') {
      searchParams.set(SearchParams.SEARCH, value);
    } else {
      searchParams.delete(SearchParams.SEARCH);
    }
    setSearchParams(searchParams, { replace: true });
  }, [ searchParams, setSearchParams ]);

  const setFilters = useCallback((params: FilterParams) => {
    // It's better not to use setPaginationParams method here to avoid an extra call of setSearchParams
    searchParams.set(SearchParams.PAGE, String(defaultPage));
    searchParams.set(SearchParams.PAGE_SIZE, String(defaultPageSize));

    if (params.org && params.org.length) {
      searchParams.set(SearchParams.ORG, params.org.join(','));
    } else {
      searchParams.delete(SearchParams.ORG);
    }

    if (params.orderStatus) {
      searchParams.set(SearchParams.ORDER_STATUS, params.orderStatus);
    } else {
      searchParams.delete(SearchParams.ORDER_STATUS);
    }

    if (params.orderGroupStatus) {
      searchParams.set(SearchParams.ORDER_GROUP_STATUS, params.orderGroupStatus);
    } else {
      searchParams.delete(SearchParams.ORDER_GROUP_STATUS);
    }

    if (params.tag && params.tag.length) {
      searchParams.set(SearchParams.TAG, params.tag.join(','));
    } else {
      searchParams.delete(SearchParams.TAG);
    }

    if (params.model) {
      searchParams.set(SearchParams.MODEL, params.model.join(','));
    } else {
      searchParams.delete(SearchParams.MODEL);
    }

    if (params.modelApi) {
      searchParams.set(SearchParams.MODEL_API, params.modelApi);
    } else {
      searchParams.delete(SearchParams.MODEL_API);
    }

    if (params.date) {
      if (params.date[0]) {
        searchParams.set(SearchParams.MIN_DATE, params.date[0].format('YYYY-MM-DD'));
      } else {
        searchParams.delete(SearchParams.MIN_DATE);
      }

      if (params.date[1]) {
        searchParams.set(SearchParams.MAX_DATE, params.date[1].format('YYYY-MM-DD'));
      } else {
        searchParams.delete(SearchParams.MAX_DATE);
      }
    } else {
      searchParams.delete(SearchParams.MIN_DATE);
      searchParams.delete(SearchParams.MAX_DATE);
    }

    if (params.creditServiceType) {
      searchParams.set(SearchParams.CREDIT_SERVICE_TYPE, params.creditServiceType);
    } else {
      searchParams.delete(SearchParams.CREDIT_SERVICE_TYPE);
    }

    if (params.fullLedger) {
      searchParams.set(SearchParams.FULL_LEDGER, params.fullLedger);
    } else {
      searchParams.delete(SearchParams.FULL_LEDGER);
    }

    if (params.type) {
      searchParams.set(SearchParams.TYPE, params.type);
    } else {
      searchParams.delete(SearchParams.TYPE);
    }

    setSearchParams(searchParams, { replace: true });
  }, [ searchParams, setSearchParams, defaultPageSize, defaultPage ]);

  const clearFilters = () => {
    Object.values(SearchParams).forEach(param => searchParams.delete(param));
    setSearchParams(searchParams, { replace: true });
  };

  const clearSearchParams = () => setSearchParams('', { replace: true });

  return {
    pageParam,
    pageSizeParam,
    searchParam,
    orgParam,
    orderStatusParam,
    orderGroupStatusParam,
    tagParam,
    modelParam,
    modelApiParam,
    minDateParam,
    maxDateParam,
    creditServiceTypeParam,
    fullLedgerParam,
    typeParam,
    setPaginationParams,
    setSearchParam,
    setFilters,
    clearFilters,
    clearSearchParams,
  };
};

export default usePaginationParams;
