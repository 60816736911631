import { Webhook } from 'api/Orgs/types';
import { useOrgWebhooks, useWebhookActions } from 'components/Dashboard/Orgs/Wizard/Webhooks/hooks';

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, InputNumber, Select, Space } from 'antd';
import React, { useEffect } from 'react';

interface Props {
  orgId: string;
}

interface FormValues {
  webhooks: Webhook[];
}

const OrgWebhooks: React.FC<Props> = ({ orgId }) => {
  const [ form ] = Form.useForm<FormValues>();

  const { isLoading: isWebhooksLoading, fetchWebhooks, webhooks, updateWebhooks } = useOrgWebhooks();
  const { isLoading, fetchWebhookActions, webhookActions } = useWebhookActions();

  useEffect(() => {
    const abortController = new AbortController();
    fetchWebhooks(orgId, abortController);

    return () => {
      abortController.abort();
    };
  }, [ orgId, fetchWebhooks ]);

  useEffect(() => {
    const abortController = new AbortController();
    fetchWebhookActions(abortController);

    return () => {
      abortController.abort();
    };
  }, [ fetchWebhookActions ]);

  useEffect(() => {
    form.setFieldValue('webhooks', webhooks);
  }, [ form, webhooks ]);

  const onFinish = (values: FormValues) => {
    const serializedValues = values.webhooks.map(webhook => ({
      ...webhook,
      id: webhook?.id || null,
      maxRetriesCount: webhook?.maxRetriesCount || 3,
      saveErrorInNotifications: !!webhook?.saveErrorInNotifications,
    }));
    updateWebhooks(orgId, serializedValues);
  };

  return (
    <Form
      form={form}
      name="orgWebhooks"
      style={{ width: 1024 }}
      layout="vertical"
      onFinish={onFinish}
      autoComplete="off"
      requiredMark={false}
      initialValues={{ webhooks }}
      disabled={isWebhooksLoading || isLoading}
    >
      <Form.List name="webhooks">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, i) => (
              <Space key={key} style={{ marginBottom: 16 }} align={i === 0 ? 'end' : 'baseline'}>

                <Form.Item
                  {...restField}
                  name={[ name, 'id' ]}
                  style={{ marginBottom: 0 }}
                  hidden
                >
                  <Input type="hidden" />
                </Form.Item>

                <Form.Item
                  {...restField}
                  name={[ name, 'webhookAction' ]}
                  rules={[ { required: true, message: 'Select action' } ]}
                  style={{ width: '280px', marginBottom: 0, marginLeft: -8 }}
                  label={i === 0 ? 'Action' : null}
                >
                  <Select placeholder="Select action" options={webhookActions} />
                </Form.Item>

                <Form.Item
                  {...restField}
                  name={[ name, 'remoteEndpoint' ]}
                  rules={[ { required: true, message: 'Enter remote endpoint' } ]}
                  style={{ width: '320px', marginBottom: 0 }}
                  label={i === 0 ? 'Remote endpoint' : null}
                >
                  <Input placeholder="Enter remote endpoint" />
                </Form.Item>

                <Form.Item
                  {...restField}
                  name={[ name, 'maxRetriesCount' ]}
                  style={{ width: '100px', marginBottom: 0 }}
                  label={i === 0 ? 'Attempts' : null}
                >
                  <InputNumber
                    placeholder="Number"
                    style={{ width: '100%' }}
                    defaultValue={3}
                    min={1}
                    max={10}
                  />
                </Form.Item>

                <Form.Item
                  {...restField}
                  name={[ name, 'saveErrorInNotifications' ]}
                  valuePropName="checked"
                  style={{ marginBottom: 0 }}
                >
                  <Checkbox>Save error</Checkbox>
                </Form.Item>

                <MinusCircleOutlined onClick={() => remove(name)} style={{ marginBottom: i === 0 ? 8 : 0 }} />

              </Space>
            ))}

            <Form.Item>
              <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                Add webhook
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>

      <Form.Item style={{ width: '150px' }}>
        <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
          Update webhooks
        </Button>
      </Form.Item>
    </Form>
  );
};

export default OrgWebhooks;
