import { downloadResultsRequest } from 'api/Data/api';
import { PdfReport } from 'api/Data/types';
import { formatDateTime } from 'app/helpers/dateTime';
import { getFileFromAxiosBlobResponse } from 'app/helpers/downloadFile';
import formatBytes from 'app/helpers/formatBytes';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';
import { getPdfReportStatusColor } from 'components/Dashboard/Orders/helpers';

import React, { useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { Button, notification, Tag, Tooltip, Typography } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

const usePdfReportColumns = () => {
  const [ loadingPdfReportId, setLoadingPdfReportId ] = useState<string | null>(null);

  const downloadResults = (orderId: string, resultType: string, pdfRequestId: string) => {
    setLoadingPdfReportId(pdfRequestId);

    downloadResultsRequest(orderId, resultType, pdfRequestId)
      .then((response) => {
        getFileFromAxiosBlobResponse(response);
      })
      .catch((reason) => {
        notification.error({ duration: 0, type: 'error', message: getErrorFromPromiseReason(reason) });
      })
      .finally(() => {
        setLoadingPdfReportId(null);
      });
  };

  const columns: ColumnsType<PdfReport> = [
    {
      title: 'Sample id',
      dataIndex: 'pdfFileName',
      render: (_, { sampleId, id }) => (
        <>
          <Typography.Text>{sampleId}</Typography.Text>
          <br />
          <Typography.Text type="secondary" style={{ fontSize: 12 }}>Request id: {id}</Typography.Text>
        </>
      ),
    },
    {
      align: 'center',
      title: 'Request №',
      dataIndex: 'requestVersion',
    },
    {
      title: 'Version',
      dataIndex: 'reportVersion',
    },
    {
      title: 'Status',
      render: (_, { status }) => {
        const color = getPdfReportStatusColor(status);
        return (
          <Tag color={color} key={status}>
            {status}
          </Tag>
        );
      },
    },
    {
      title: 'Size',
      render: (_, { fileSizeBytes }) => {
        if (fileSizeBytes) {
          return formatBytes(fileSizeBytes);
        }
      },
    },
    {
      title: 'Report type',
      key: 'reportType',
      align: 'center',
      render: (_, { reportType }) => <Tag>{reportType}</Tag>,
    },
    {
      title: 'Request type',
      key: 'requestType',
      align: 'center',
      render: (_, { requestType }) => <Tag>{requestType}</Tag>,
    },
    {
      title: 'Started',
      render: (_, { startedAt }) => <Typography.Text style={{ whiteSpace: 'nowrap' }}>{formatDateTime(startedAt)}</Typography.Text>,
    },
    {
      title: 'Completed',
      render: (_, { completedAt }) => {
        if (completedAt) {
          return <Typography.Text style={{ whiteSpace: 'nowrap' }}>{formatDateTime(completedAt)}</Typography.Text>;
        }
      },
    },
    {
      align: 'center',
      title: '',
      key: 'results',
      render: (_, { id, status, orderId }) => {
        if (status !== 'PENDING' && status !== 'QC_FAILED') {
          return (
            <Tooltip title={status === 'COMPLETED' ? 'Download PDF report' : 'Download error log'}>
              <Button
                data-testid="download-pdf-report-button"
                style={{ padding: 0 }}
                type="link"
                icon={<DownloadOutlined />}
                loading={loadingPdfReportId === id}
                onClick={() => downloadResults(orderId, 'PDF_REPORT', id)}
              />
            </Tooltip>
          );
        }
      },
    },
  ];

  return { columns };
};

export default usePdfReportColumns;
