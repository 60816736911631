import { deleteCreditExchangeRequest } from 'api/Credit/api';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { message, notification } from 'antd';
import { useState } from 'react';

const useDeleteCreditExchange = (onCreditExchangeDeleted: (creditExchangeId: string) => void) => {
  const [ deletingId, setDeletingId ] = useState<string | null>(null);

  const deleteCreditExchange = (creditExchangeId: string) => {
    setDeletingId(creditExchangeId);

    deleteCreditExchangeRequest(creditExchangeId)
      .then(() => {
        message.success('Exchange was removed');
        onCreditExchangeDeleted(creditExchangeId);
      })
      .catch((reason) => {
        notification.error({ duration: 0, type: 'error', message: getErrorFromPromiseReason(reason) });
      })
      .finally(() => {
        setDeletingId(null);
      });
  };

  return { deletingId, deleteCreditExchange };
};

export default useDeleteCreditExchange;
