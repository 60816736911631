import { getSetRequest, getSetExportRequest, getSuperSetRequest, getSuperSetExportRequest } from 'api/Dashboard/api';
import { PaginatedSetLabels, PaginatedSuperSetLabels, TableFilterParams } from 'api/Dashboard/types';
import { getFileFromAxiosBlobResponse } from 'app/helpers/downloadFile';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';
import { ChartFilter, LabelType } from 'components/Dashboard/Plots/types';

import { message, notification } from 'antd';
import { useCallback, useState } from 'react';

const useLabels = (type: LabelType) => {
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ isExporting, setIsExporting ] = useState<boolean>(false);
  const [ response, setResponse ] = useState<PaginatedSetLabels | PaginatedSuperSetLabels>({ count: 0, results: [] });
  const fetchCallback = type === 'set' ? getSetRequest : getSuperSetRequest;
  const exportCallback = type === 'set' ? getSetExportRequest : getSuperSetExportRequest;

  const fetchLabels = useCallback((
    pageNumber: number,
    pageSize: number,
    tableFilter: TableFilterParams,
    filter: ChartFilter,
    abortController?: AbortController,
  ) => {
    setIsLoading(true);
    fetchCallback(pageNumber, pageSize, tableFilter, filter, abortController)
      .then((response) => {
        setResponse(response.data);
      })
      .catch((reason) => {
        if (!abortController?.signal.aborted) {
          message.error(getErrorFromPromiseReason(reason));
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [ fetchCallback ]);

  const exportLabels = useCallback((tableFilter: TableFilterParams, filter: ChartFilter) => {
    setIsExporting(true);
    exportCallback(tableFilter, filter)
      .then((response) => {
        getFileFromAxiosBlobResponse(response);
      })
      .catch((reason) => {
        notification.error({ duration: 0, type: 'error', message: getErrorFromPromiseReason(reason) });
      })
      .finally(() => {
        setIsExporting(false);
      });
  }, [ exportCallback ]);

  return { response, isLoading, fetchLabels, isExporting, exportLabels };
};

export default useLabels;
