import { OrgModel } from 'api/Orgs/types';
import { getUserRequest } from 'api/Users/api';
import { MyUser, Role } from 'api/Users/types';
import { AppThunk, RootState } from 'store/types';
import { UsersState, ChangeOrgModelsPayload, ChangeUserOrgWebhooksPayload } from 'store/users/types';

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

export const initialState: UsersState = {
  myUser: {
    id: '',
    email: '',
    firstName: '',
    lastName: '',
    role: Role.ORG_USER,
    isActive: false,
    createdAt: '',
    updatedAt: '',
    deletedAt: null,
    org: {
      id: '',
      name: '',
      domains: [],
      availableModels: [],
      reportPdfEnabled: false,
      reportPrsEnabled: false,
      reportPrsClinicalEnabled: false,
      webhooksEnabled: false,
      webhooksSecret: '',
    },
  },
  status: 'loading',
};

export const fetchMyUserAsync = createAsyncThunk(
  'users/me',
  async (userId: string) => {
    const response = await getUserRequest(userId);
    return response.data;
  },
);

export const fetchMyUser = (isAuthenticated: boolean): AppThunk => (dispatch, getState) => {
  if (isAuthenticated) {
    const { id } = selectMyUser(getState());
    if (!id) {
      dispatch(fetchMyUserAsync('me'));
    } else {
      dispatch(setStatusAsIdle());
    }
  } else {
    dispatch(resetUser());
  }
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setStatusAsIdle: (state) => {
      state.status = 'idle';
    },
    resetUser: (state) => {
      state.status = 'idle';
      state.myUser = initialState.myUser;
    },
    changeUserOrgModels: (state, action: PayloadAction<ChangeOrgModelsPayload>) => {
      const { orgId, orgModels } = action.payload;
      if (orgId === state.myUser.org.id) {
        state.myUser.org.availableModels = orgModels.map((orgModel: OrgModel) => orgModel.modelName);
      }
    },
    changeUserOrgWebhooksSecret: (state, action: PayloadAction<ChangeUserOrgWebhooksPayload>) => {
      const { orgId, value } = action.payload;
      if (orgId === state.myUser.org.id) {
        state.myUser.org.webhooksSecret = value;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMyUserAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchMyUserAsync.fulfilled, (state, action: PayloadAction<MyUser>) => {
        state.status = 'idle';
        state.myUser = action.payload;
      })
      .addCase(fetchMyUserAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { resetUser, setStatusAsIdle, changeUserOrgModels, changeUserOrgWebhooksSecret } = usersSlice.actions;

export const selectMyUser = (state: RootState) => state.users.myUser;
export const selectUsersStatus = (state: RootState) => state.users.status;

export default usersSlice.reducer;
