import { useOrgWebhooksSecret } from 'components/Dashboard/Orgs/Wizard/Webhooks/hooks';
import OrgWebhooks from 'components/Dashboard/Orgs/Wizard/Webhooks/OrgWebhooks/OrgWebhooks';

import { ReloadOutlined } from '@ant-design/icons';
import { Button, Form, Input, Space, Typography } from 'antd';
import React from 'react';

interface Props {
  orgId: string;
  orgWebhooksSecret: string;
}

const Webhooks: React.FC<Props> = ({ orgId, orgWebhooksSecret }) => {
  const { isLoading, generateSecret } = useOrgWebhooksSecret();

  return (
    <>
      <Space align="end" style={{ marginBottom: 24 }}>
        <Form.Item
          layout="vertical"
          label="Secret key"
          style={{ width: '280px', marginBottom: 0 }}
        >
          <Input disabled value={orgWebhooksSecret} />
        </Form.Item>

        <Button
          type="primary"
          icon={<ReloadOutlined style={{ fontSize: 16 }} />}
          onClick={() => generateSecret(orgId)}
          disabled={isLoading}
        >
          Generate a new secret key
        </Button>
      </Space>

      <Typography.Title level={5}>Webhooks</Typography.Title>

      <OrgWebhooks orgId={orgId} />
    </>
  );
};

export default Webhooks;
