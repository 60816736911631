import { updateOrgModelsRequest } from 'api/Orgs/api';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';
import { useAppDispatch } from 'store/hooks';
import { changeUserOrgModels } from 'store/users/usersSlice';

import { message, notification } from 'antd';
import type { TransferProps } from 'antd';
import { useState } from 'react';

const useUpdateOrgModels = () => {
  const dispatch = useAppDispatch();
  const [ isUpdating, setIsUpdating ] = useState<boolean>(false);

  const updateOrgModels = (orgId: string, data: TransferProps['targetKeys']) => {
    setIsUpdating(true);

    updateOrgModelsRequest(orgId, data)
      .then(({ data }) => {
        dispatch(changeUserOrgModels({ orgModels: data, orgId }));
        message.success('Organization models were updated');
      })
      .catch((reason) => {
        notification.error({ duration: 0, type: 'error', message: getErrorFromPromiseReason(reason) });
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  return { isUpdating, updateOrgModels };
};

export default useUpdateOrgModels;
