import { CreditExchange } from 'api/Credit/types';
import { updateCreditExchangeRequest } from 'api/Credit/api';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { message, notification } from 'antd';
import { useState } from 'react';

const useUpdateCreditExchange = (onCreditExchangeUpdated: (creditExchange: CreditExchange) => void) => {
  const [ isUpdating, setIsUpdating ] = useState<boolean>(false);

  const updateCreditExchange = (creditExchangeId: string, price: number, rate: number) => {
    setIsUpdating(true);

    updateCreditExchangeRequest(creditExchangeId, price, rate)
      .then(({ data }) => {
        message.success('Exchange was updated');
        onCreditExchangeUpdated(data);
      })
      .catch((reason) => {
        notification.error({ duration: 0, type: 'error', message: getErrorFromPromiseReason(reason) });
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  return { isUpdating, updateCreditExchange };
};

export default useUpdateCreditExchange;
