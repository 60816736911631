const orgsUrl = 'organizations';
const orgItemUrl = (orgId: string) => `${orgsUrl}/${orgId}`;
const orgModelsUrl = (orgId: string, hideDeprecated = false) => `${orgItemUrl(orgId)}/models?hide_deprecated=${hideDeprecated}`;
const orgSftpUsersUrl = (orgId: string) => `${orgItemUrl(orgId)}/sftp_users`;
const orgGenerateApiKeyUrl = (orgId: string) => `${orgItemUrl(orgId)}/generate-api-key`;
const orgSftpUserItemUrl = (orgId: string, sftpUserId: string) => `${orgSftpUsersUrl(orgId)}/${sftpUserId}`;
const orgSftpUserSshKeysUrl = (orgId: string, sftpUserId: string) => `${orgSftpUserItemUrl(orgId, sftpUserId)}/ssh_keys`;
const orgSftpUserSshKeyItemUrl = (orgId: string, sftpUserId: string, sshKeyId: string) => `${orgSftpUserSshKeysUrl(orgId, sftpUserId)}/${sshKeyId}`;
const orgWebhooksInfo = (orgId: string) => `${orgItemUrl(orgId)}/webhooks-info`;
const orgWebhooksGenerateSecret = (orgId: string) => `${orgItemUrl(orgId)}/generate-webhooks-secret`;

export default {
  orgsUrl,
  orgItemUrl,
  orgModelsUrl,
  orgSftpUsersUrl,
  orgGenerateApiKeyUrl,
  orgSftpUserItemUrl,
  orgSftpUserSshKeysUrl,
  orgSftpUserSshKeyItemUrl,
  orgWebhooksInfo,
  orgWebhooksGenerateSecret,
};
