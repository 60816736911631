import { requestPdfRequest } from 'api/Data/api';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { useState } from 'react';
import { message, notification } from 'antd';

const useRegeneratePdf = (onSuccess: (orderId: string) => void, finalCallback: () => void) => {
  const [ isRegenerating, setIsRegenerating ] = useState<boolean>(false);

  const regeneratePdf = (orderId: string) => {
    setIsRegenerating(true);
    requestPdfRequest(orderId)
      .then(({ data }) => {
        onSuccess(orderId);
        message.success(data.detail);
      })
      .catch((reason) => {
        notification.error({ duration: 0, type: 'error', message: getErrorFromPromiseReason(reason) });
      })
      .finally(() => {
        finalCallback();
        setIsRegenerating(false);
      });
  };

  return { isRegenerating, regeneratePdf };
};

export default useRegeneratePdf;
