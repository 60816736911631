import { createOrgRequest } from 'api/Orgs/api';
import { CreateUpdateOrgData } from 'api/Orgs/types';
import { dashboardUrl, orgsUrl } from 'app/consts/urls';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';
import { OrgLocationState } from 'components/Dashboard/Orgs/types';
import { addOrg } from 'store/orgs/orgsSlice';
import { useAppDispatch } from 'store/hooks';

import { message, notification } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const useCreateOrg = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [ isCreating, setIsCreating ] = useState<boolean>(false);

  const createOrg = (data: CreateUpdateOrgData) => {
    setIsCreating(true);

    createOrgRequest(data)
      .then(({ data }) => {
        const state: OrgLocationState = { hasOrgJustCreated: true };
        dispatch(addOrg(data));
        message.success('Organization was created');
        navigate(`/${dashboardUrl}/${orgsUrl}/${data.id}`, { state });
      })
      .catch((reason) => {
        notification.error({ duration: 0, type: 'error', message: getErrorFromPromiseReason(reason) });
      })
      .finally(() => {
        setIsCreating(false);
      });
  };

  return { isCreating, createOrg };
};

export default useCreateOrg;
