const notificationsUrl = 'notification';
const notificationsListUrl = (page: number, pageSize: number, watched?: boolean) => {
  return [
    `${notificationsUrl}?page=${page}`,
    `&page_size=${pageSize}`,
    watched !== undefined ? `&watched=${watched}` : '',
  ].join('');
};
const notificationsWatchUrl = (notificationId?: string, watchAll = false) => {
  return [
    `${notificationsUrl}/watch`,
    `?watch_all=${watchAll}`,
    notificationId !== undefined ? `&notification_id=${notificationId}` : '',
  ].join('');
};
const webhookActionsUrl = `${notificationsUrl}/webhook-actions`;

export default { notificationsListUrl, notificationsWatchUrl, webhookActionsUrl };
