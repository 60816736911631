import { getWebhookActions } from 'api/Notifications/api';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';
import { SelectOption } from 'app/types/Forms';

import { message } from 'antd';
import { useCallback, useState } from 'react';

const useWebhookActions = () => {
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ webhookActions, setWebhookActions ] = useState<SelectOption[]>([]);

  const fetchWebhookActions = useCallback((abortController: AbortController) => {
    setIsLoading(true);
    getWebhookActions(abortController)
      .then((response) => {
        const selectOptions = response.data.map(item => ({ label: item.name, value: item.value }));
        setWebhookActions(selectOptions);
      })
      .catch((reason) => {
        if (!abortController?.signal.aborted) {
          message.error(getErrorFromPromiseReason(reason));
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return { webhookActions, isLoading, fetchWebhookActions };
};

export default useWebhookActions;
