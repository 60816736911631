import { Org } from 'api/Orgs/types';
import { Role, Status, User, PaginatedUsers } from 'api/Users/types';
import TableTopPanel from 'app/common_components/TableTopPanel/TableTopPanel';
import usePaginationParams from 'app/hooks/usePaginationParams';
import useTablePagination from 'app/hooks/useTablePagination';
import { useWebUsersColumns, useDeleteUser, useResendInvite, useUsers } from 'components/Dashboard/Users/hooks';
import NewUserForm from 'components/Dashboard/Users/NewUserForm/NewUserForm';
import UpdateUserForm from 'components/Dashboard/Users/UpdateUserForm/UpdateUserForm';
import UsersFilter from 'components/Dashboard/Users/UsersFilter/UsersFilter';
import { useAppSelector } from 'store/hooks';
import { selectMyUser } from 'store/users/usersSlice';

import React, { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, message, Modal, Table, Typography } from 'antd';
import { DateTime } from 'luxon';

const { Title, Text } = Typography;

interface Props {
  org?: Org;
}

const WebUsers: React.FC<Props> = ({ org }) => {
  const { role: myUserRole } = useAppSelector(selectMyUser);
  const { pageParam, pageSizeParam, searchParam, orgParam } = usePaginationParams();
  const { tablePagination } = useTablePagination();

  const [ isNewUserModalOpen, setIsNewUserModalOpen ] = useState<boolean>(false);
  const openNewUserModal = () => setIsNewUserModalOpen(true);
  const closeNewUserModal = () => setIsNewUserModalOpen(false);

  const [ userIdToDelete, setUserIdToDelete ] = useState<string | null>(null);
  const openDeleteUserModal = (userId: string) => setUserIdToDelete(userId);
  const closeDeleteUserModal = () => setUserIdToDelete(null);

  const [ userIdToResendInvite, setUserIdToResendInvite ] = useState<string | null>(null);
  const openResendInviteModal = (userId: string) => setUserIdToResendInvite(userId);
  const closeResendInviteModal = () => setUserIdToResendInvite(null);

  const [ userToUpdate, setUserToUpdate ] = useState<User | null>(null);
  const openUpdateUserModal = (user: User) => setUserToUpdate(user);
  const closeUpdateUserModal = () => setUserToUpdate(null);

  const { columns } = useWebUsersColumns(openDeleteUserModal, openResendInviteModal, openUpdateUserModal);
  const { response, isLoading, fetchUsers, setResponse } = useUsers();

  const onUserInvited = (user: User) => {
    setResponse((prevState: PaginatedUsers) => {
      if (prevState.results.length === pagination.pageSize) {
        prevState.results.pop();
      }
      prevState.results.unshift(user);

      return { count: prevState.count + 1, results: [ ...prevState.results ] };
    });
    closeNewUserModal();
    message.success('User was invited');
  };

  const onUserUpdated = (user: User) => {
    const userIndex = response.results.findIndex((u: User) => u.id === user.id);

    setResponse({ results: [
      ...response.results.slice(0, userIndex),
      { ...response.results[userIndex], ...user },
      ...response.results.slice(userIndex + 1, response.results.length),
    ],
    count: response.count });
    message.success('User was updated');
  };

  const onUserDeleted = (userId: string) => {
    const userIndex = response.results.findIndex((user: User) => user.id === userId);

    setResponse({ results: [
      ...response.results.slice(0, userIndex),
      { ...response.results[userIndex], deletedAt: DateTime.now().toString(), status: Status.DELETED },
      ...response.results.slice(userIndex + 1, response.results.length),
    ],
    count: response.count });
    message.success('User was deleted');
  };

  const reload = () => {
    const orgNames = org ? org.name : orgParam;
    fetchUsers(pageParam, pageSizeParam, searchParam, orgNames, false);
  };

  const { deleteUser, isDeleting } = useDeleteUser(onUserDeleted, closeDeleteUserModal);

  const { resendInvite, isResending } = useResendInvite(closeResendInviteModal);

  useEffect(() => {
    const abortController = new AbortController();
    const orgNames = org ? org.name : orgParam;
    fetchUsers(pageParam, pageSizeParam, searchParam, orgNames, false, abortController);

    return () => {
      abortController.abort();
    };
  }, [ fetchUsers, pageParam, pageSizeParam, searchParam, orgParam, org ]);

  const pagination = tablePagination(response.count);

  const filter = (myUserRole === Role.SYSTEM_ADMIN && !org) ? UsersFilter : undefined;

  return (
    <>
      <TableTopPanel
        RightColumn={<Button type="primary" onClick={openNewUserModal} icon={<PlusOutlined />}>New user</Button>}
        onReload={reload}
        Filter={filter}
        placeholder="Search emails"
      />

      <Table
        rowKey={(user: User) => user.id}
        columns={columns}
        dataSource={response.results}
        pagination={pagination}
        loading={isLoading}
        scroll={{ x: true }}
      />

      <Modal
        title={<Title level={4}>Delete user</Title>}
        open={!!userIdToDelete}
        width={420}
        onCancel={closeDeleteUserModal}
        onOk={() => deleteUser(userIdToDelete!)}
        confirmLoading={isDeleting}
      >
        <div style={{ margin: '24px 0' }}>
          <Text>Are you sure you want to delete this user?</Text>
        </div>
      </Modal>

      <Modal
        title={<Title level={4}>Resend invitation</Title>}
        open={!!userIdToResendInvite}
        width={420}
        onCancel={closeResendInviteModal}
        onOk={() => resendInvite(userIdToResendInvite!)}
        confirmLoading={isResending}
      >
        <div style={{ margin: '24px 0' }}>
          <Text>Are you sure you want to resend the invitation to this user?</Text>
        </div>
      </Modal>

      {userToUpdate && (
        <Modal
          title={<Title level={4}>Update user</Title>}
          open={!!userToUpdate}
          footer={null}
          width={420}
          onCancel={closeUpdateUserModal}
          destroyOnClose
          data-testid="update-user-modal"
        >
          <UpdateUserForm onCancel={closeUpdateUserModal} onUserUpdated={onUserUpdated} user={userToUpdate} />
        </Modal>
      )}

      <Modal
        title={<Title level={4}>New user</Title>}
        open={isNewUserModalOpen}
        footer={null}
        width={420}
        onCancel={closeNewUserModal}
        destroyOnClose
        data-testid="new-user-modal"
      >
        <NewUserForm onCancel={closeNewUserModal} onUserInvited={onUserInvited} org={org} />
      </Modal>
    </>
  );
};

export default WebUsers;
