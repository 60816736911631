import { createOrderRequest } from 'api/Exec/api';
import { Order } from 'api/Exec/types';
import { PrsPdfMetadata } from 'app/common_components/PdfFormData/types';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { useState } from 'react';
import { notification } from 'antd';
import { omitBy, isUndefined, isNull } from 'lodash';

const useCreateOrder = (onOrderCreated: (order: Order) => void) => {
  const [ isLoading, setIsLoading ] = useState<boolean>(false);

  const createOrder = (fileId: string, modelName: string, tagIds: string[], pdfData?: PrsPdfMetadata) => {
    setIsLoading(true);

    const stringedPdfData = JSON.stringify(pdfData || {}); // need this step to normalize date fields
    const pdfMetadata = JSON.parse(stringedPdfData) as PrsPdfMetadata;
    // request from backend side: remove all empty fields from request
    const filteredPdfMetadata = omitBy(pdfMetadata, v => isUndefined(v) || isNull(v) || v === '');

    createOrderRequest(fileId, modelName, tagIds, filteredPdfMetadata)
      .then(({ data }) => {
        onOrderCreated(data[0]);
      })
      .catch((reason) => {
        notification.error({ duration: 0, type: 'error', message: getErrorFromPromiseReason(reason) });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return { isLoading, createOrder };
};

export default useCreateOrder;
