import { FilesFilterParams, PdfReportsFilterParams } from 'api/Data/types';

const rootUrl = 'data';
const filesUrl = `${rootUrl}/files`;
const filesListUrl = (page: number, pageSize: number, filter: FilesFilterParams) => {
  const { file, onlyAcceptable, orgs, minDate, maxDate, showVirtual } = filter;
  return [
    filesUrl,
    `?page=${page}`,
    `&page_size=${pageSize}`,
    `&file=${file}`,
    `&only_acceptable=${onlyAcceptable}`,
    `&org_names=${orgs}`,
    `&min_date=${minDate}`,
    `&max_date=${maxDate}`,
    `&show_virtual=${showVirtual}`,
  ].join('');
};
const uploadFileUrl = `${filesUrl}/upload`;
const fileResourceUrl = (fileId: string) => `${filesUrl}/${fileId}`;
const downloadFileUrl = (fileId: string) => `${fileResourceUrl(fileId)}/download`;
const resultsUrl = `${rootUrl}/results`;
const resultsUploadUrl = `${rootUrl}/results/upload`;
const resultsResourceUrl = (orderId: string) => `${resultsUrl}/${orderId}`;
const downloadResultsUrl = (orderId: string, resultType: string, pdfRequestId?: string) => {
  return [
    `${resultsResourceUrl(orderId)}/download?result_type=${resultType}`,
    pdfRequestId ? `&pdf_request_id=${pdfRequestId}` : '',
  ].join('');
};
const requestPdfUrl = (orderId: string) => `${resultsResourceUrl(orderId)}/request_pdf`;
const pdfReportsListUrl = (orderId: string, page: number, pageSize: number, filter: PdfReportsFilterParams) => {
  const { requestVersion, reportVersion, sampleId } = filter;
  return [
    `${resultsResourceUrl(orderId)}/pdf_report`,
    `?page=${page}`,
    `&page_size=${pageSize}`,
    `&request_version=${requestVersion}`,
    `&report_version=${reportVersion}`,
    `&sample_id=${sampleId}`,
  ].join('');
};

export default {
  filesListUrl,
  uploadFileUrl,
  downloadResultsUrl,
  fileResourceUrl,
  downloadFileUrl,
  requestPdfUrl,
  pdfReportsListUrl,
  resultsUploadUrl,
};
