import Webhooks from 'components/Dashboard/Orgs/Wizard/Webhooks/Webhooks';
import { DashboardPageProps } from 'components/Dashboard/types';
import ApiUsers from 'components/Dashboard/Users/ApiUsers/ApiUsers';
import { useAppSelector } from 'store/hooks';
import { selectMyUser } from 'store/users/usersSlice';

import React, { useEffect } from 'react';
import { Tabs, TabsProps } from 'antd';

const Integrations: React.FC<DashboardPageProps> = ({ setTitle }) => {
  const { org: { id, webhooksEnabled, webhooksSecret } } = useAppSelector(selectMyUser);

  useEffect(() => {
    setTitle('Integrations');
  }, [ setTitle ]);

  const tabs: TabsProps['items'] = [
    {
      key: 'api',
      label: 'API keys',
      children: <ApiUsers />,
    },
    {
      key: 'webhooks',
      label: 'Webhooks',
      children: <Webhooks orgId={id} orgWebhooksSecret={webhooksSecret} />,
      disabled: !webhooksEnabled,
    },
  ];

  return <Tabs defaultActiveKey="api" items={tabs} destroyInactiveTabPane />;
};

export default Integrations;
