import { File } from 'api/Data/types';
import { Order, OrdersFilterParams, Status } from 'api/Exec/types';
import TableTopPanel from 'app/common_components/TableTopPanel/TableTopPanel';
import CreateAncestryCallForm from 'components/Dashboard/Files/CreateAncestryCallForm/CreateAncestryCallForm';
import usePaginationParams from 'app/hooks/usePaginationParams';
import useTablePagination from 'app/hooks/useTablePagination';
import { DashboardPageProps } from 'components/Dashboard/types';
import { useOrderColumns, useCancelOrder, useOrders, useRegeneratePdf } from 'components/Dashboard/Orders/hooks';
import NewOrderForm from 'components/Dashboard/Orders/NewOrderForm/NewOrderForm';
import OrdersFilter from 'components/Dashboard/Orders/OrdersFilter/OrdersFilter';
import UpdateOrderForm from 'components/Dashboard/Orders/UpdateOrderForm/UpdateOrderForm';
import PdfReports from 'components/Dashboard/Orders/PdfReports/PdfReports';

import React, { useCallback, useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, message, Modal, Table, Typography } from 'antd';

export type RerunFile = Pick<File, 'id'> & { tagIds: string[] };

export type UpdatedOrder = Pick<Order, 'id'> & { tagIds: string[] };

const { Title, Text } = Typography;

const Orders: React.FC<DashboardPageProps> = ({ setTitle }) => {
  const {
    pageParam,
    pageSizeParam,
    searchParam,
    orgParam,
    orderStatusParam,
    orderGroupStatusParam,
    tagParam,
    modelParam,
    modelApiParam,
    minDateParam,
    maxDateParam,
    typeParam,
  } = usePaginationParams();
  const { tablePagination } = useTablePagination();

  const [ isNewOrderModalOpen, setIsNewOrderModalOpen ] = useState<boolean>(false);
  const openNewOrderModal = () => setIsNewOrderModalOpen(true);
  const closeNewOrderModal = () => setIsNewOrderModalOpen(false);

  const [ rerunOrderFile, setRerunOrderFile ] = useState<RerunFile | null>(null);
  const rerunOrder = (file: RerunFile) => setRerunOrderFile(file);
  const closeRerunOrderModal = () => setRerunOrderFile(null);

  const [ updatedOrder, setUpdatedOrder ] = useState<UpdatedOrder | null>(null);
  const updateOrder = (order: UpdatedOrder) => setUpdatedOrder(order);
  const closeUpdateOrderModal = () => setUpdatedOrder(null);

  const [ orderIdToCancel, setOrderIdToCancel ] = useState<string | null>(null);
  const openCancelOrderModal = (orderId: string) => setOrderIdToCancel(orderId);
  const closeCancelOrderModal = () => setOrderIdToCancel(null);

  const [ orderIdToRegeneratePdf, setOrderIdToRegeneratePdf ] = useState<string | null>(null);
  const openRegeneratePdfModal = (orderId: string) => setOrderIdToRegeneratePdf(orderId);
  const closeRegeneratePdfModal = () => setOrderIdToRegeneratePdf(null);

  const [ orderIdToGetPdfReports, setOrderIdToGetPdfReports ] = useState<string | null>(null);
  const openPdfReportsModal = (orderId: string) => setOrderIdToGetPdfReports(orderId);
  const closePdfReportsModal = () => setOrderIdToGetPdfReports(null);

  const { columns } = useOrderColumns(rerunOrder, openCancelOrderModal, updateOrder, openRegeneratePdfModal, openPdfReportsModal);
  const { response, isLoading, fetchOrders, setResponse } = useOrders();

  const onOrderUpdated = (order: Order) => {
    const orderIndex = response.results.findIndex(o => o.id === order.id);

    setResponse({ results: [
      ...response.results.slice(0, orderIndex),
      { ...response.results[orderIndex], ...order },
      ...response.results.slice(orderIndex + 1, response.results.length),
    ],
    count: response.count });
    message.success('Order was updated');
  };

  const onOrderCanceled = (orderId: string) => {
    const orderIndex = response.results.findIndex(order => order.id === orderId);

    setResponse({ results: [
      ...response.results.slice(0, orderIndex),
      { ...response.results[orderIndex], status: Status.CANCELING },
      ...response.results.slice(orderIndex + 1, response.results.length),
    ],
    count: response.count });
    message.success('Order was canceled');
  };

  const { isCanceling, cancelOrder } = useCancelOrder(onOrderCanceled, closeCancelOrderModal);

  const onOrderCreated = (order: Order) => {
    setResponse((prevState) => {
      if (prevState.results.length === pagination.pageSize) {
        prevState.results.pop();
      }
      prevState.results.unshift(order);

      return { count: prevState.count + 1, results: [ ...prevState.results ] };
    });
    closeNewOrderModal();
    closeRerunOrderModal();
    message.success('Order was created');
  };

  const onPdfRegenerated = (orderId: string) => {
    const orderIndex = response.results.findIndex(order => order.id === orderId);

    setResponse({ results: [
      ...response.results.slice(0, orderIndex),
      { ...response.results[orderIndex], status: Status.MAKING_REPORT },
      ...response.results.slice(orderIndex + 1, response.results.length),
    ],
    count: response.count });
    closeRegeneratePdfModal();
  };

  const { regeneratePdf, isRegenerating } = useRegeneratePdf(onPdfRegenerated, closeRegeneratePdfModal);

  const getFilter = useCallback((): OrdersFilterParams => ({
    search: searchParam,
    orgs: orgParam.split(','),
    status: orderStatusParam,
    groupStatus: orderGroupStatusParam,
    tags: tagParam.split(','),
    model: modelParam,
    modelApi: modelApiParam,
    minDate: minDateParam,
    maxDate: maxDateParam,
    type: typeParam,
  }), [ searchParam, orgParam, tagParam, orderStatusParam, orderGroupStatusParam, modelParam, modelApiParam, minDateParam, maxDateParam, typeParam ]);

  const reload = () => fetchOrders(pageParam, pageSizeParam, getFilter());

  useEffect(() => {
    setTitle('Orders');
  }, [ setTitle ]);

  useEffect(() => {
    const abortController = new AbortController();
    fetchOrders(pageParam, pageSizeParam, getFilter(), abortController);

    return () => {
      abortController.abort();
    };
  }, [ fetchOrders, pageParam, pageSizeParam, getFilter ]);

  const pagination = tablePagination(response.count);

  return (
    <>
      <TableTopPanel
        RightColumn={<Button type="primary" onClick={openNewOrderModal} icon={<PlusOutlined />}>New order</Button>}
        onReload={reload}
        Filter={OrdersFilter}
        placeholder="Search files or orders"
      />

      <Table
        rowKey={order => order.id}
        columns={columns}
        dataSource={response.results}
        pagination={pagination}
        loading={isLoading}
        scroll={{ x: true }}
      />

      <Modal
        title={<Title level={4}>Cancel order</Title>}
        open={!!orderIdToCancel}
        width={420}
        onCancel={closeCancelOrderModal}
        onOk={() => cancelOrder(orderIdToCancel!)}
        confirmLoading={isCanceling}
        destroyOnClose
      >
        <div style={{ marginTop: 24 }}>
          <Text>Are you sure you want to cancel this order?</Text>
        </div>
      </Modal>

      <Modal
        title={<Title level={4}>Regenerate PDF</Title>}
        open={!!orderIdToRegeneratePdf}
        width={420}
        onCancel={closeRegeneratePdfModal}
        onOk={() => regeneratePdf(orderIdToRegeneratePdf!)}
        destroyOnClose
        data-testid="regenerate-pdf-modal"
        confirmLoading={isRegenerating}
      >
        <div style={{ margin: '24px 0' }}>
          <Text>Are you sure you want to regenerate the PDF report?</Text>
        </div>
      </Modal>

      <Modal
        title={<Title level={4}>PDF reports</Title>}
        open={!!orderIdToGetPdfReports}
        width="90%"
        footer={[
          <Button type="primary" key="close" onClick={closePdfReportsModal}>
            Close
          </Button>,
        ]}
        onCancel={closePdfReportsModal}
        destroyOnClose
        data-testid="pdf-reports-modal"
      >
        <PdfReports orderId={orderIdToGetPdfReports!} />
      </Modal>

      <Modal
        title={<Title level={4}>New order</Title>}
        open={isNewOrderModalOpen}
        footer={null}
        width={640}
        onCancel={closeNewOrderModal}
        destroyOnClose
        data-testid="new-order-modal"
      >
        <NewOrderForm onCancel={closeNewOrderModal} onOrderCreated={onOrderCreated} />
      </Modal>

      <Modal
        title={<Title level={4}>Update tags</Title>}
        open={!!updatedOrder}
        footer={null}
        width={420}
        onCancel={closeUpdateOrderModal}
        destroyOnClose
        data-testid="update-order-modal"
      >
        {updatedOrder && (
          <UpdateOrderForm
            orderId={updatedOrder.id}
            onCancel={closeUpdateOrderModal}
            onOrderUpdated={onOrderUpdated}
            tagIds={updatedOrder.tagIds}
          />
        )}
      </Modal>

      <Modal
        title={<Title level={4}>Rerun order</Title>}
        open={!!rerunOrderFile}
        footer={null}
        width={640}
        onCancel={closeRerunOrderModal}
        destroyOnClose
        data-testid="rerun-order-modal"
      >
        {rerunOrderFile && (
          <CreateAncestryCallForm
            fileId={rerunOrderFile.id}
            onCancel={closeRerunOrderModal}
            onOrderCreated={onOrderCreated}
            tagIds={rerunOrderFile.tagIds}
          />
        )}
      </Modal>
    </>
  );
};

export default Orders;
