import { deleteCreditServiceRequest } from 'api/Credit/api';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { message, notification } from 'antd';
import { useState } from 'react';

const useDeleteCreditService = (onCreditServiceDeleted: (creditServiceId: string) => void) => {
  const [ isDeleting, setIsDeleting ] = useState<boolean>(false);

  const deleteCreditService = (creditServiceId: string) => {
    setIsDeleting(true);

    deleteCreditServiceRequest(creditServiceId)
      .then(() => {
        message.success('Credit service was removed');
        onCreditServiceDeleted(creditServiceId);
      })
      .catch((reason) => {
        notification.error({ duration: 0, type: 'error', message: getErrorFromPromiseReason(reason) });
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  return { isDeleting, deleteCreditService };
};

export default useDeleteCreditService;
