import { PdfReport } from 'api/Data/types';
import { Status } from 'api/Exec/types';

export const canCancelOrder = (status: Status) => {
  const acceptableStatuses = [ Status.REGISTERED, Status.PREPARING, Status.SUBMITTED ];
  return acceptableStatuses.includes(status);
};

export const formatDuration = (duration: number | null): string => {
  if (!duration) {
    return '00:00:00';
  }

  const absDuration = Math.abs(duration);
  const h = Math.floor(absDuration / 3600).toString().padStart(2, '0');
  const m = Math.floor((absDuration % 3600) / 60).toString().padStart(2, '0');
  const s = Math.floor(absDuration % 60).toString().padStart(2, '0');

  return `${h}:${m}:${s}`;
};

export const getOrderStatusColor = (status: Status) => {
  switch (status) {
    case Status.COMPLETED:
      return 'green';
    case Status.FAILED:
      return 'red';
    case Status.REPORTS_FAILED:
      return 'red';
    case Status.QC_FAILED:
      return 'red';
    case Status.CANCELED:
      return 'orange';
    default:
      return 'geekblue';
  }
};

export const getPdfReportStatusColor = (status: PdfReport['status']) => {
  switch (status) {
    case 'COMPLETED':
      return 'green';
    case 'FAILED':
      return 'red';
    case 'QC_FAILED':
      return 'red';
    default:
      return 'geekblue';
  }
};
