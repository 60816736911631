import { updateOrderRequest } from 'api/Exec/api';
import { Order } from 'api/Exec/types';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { useState } from 'react';
import { notification } from 'antd';

const useUpdateOrder = (onOrderUpdated: (order: Order) => void, finalCallback: () => void) => {
  const [ isLoading, setIsLoading ] = useState<boolean>(false);

  const updateOrder = (orderId: string, tagIds: string[]) => {
    setIsLoading(true);

    updateOrderRequest(orderId, tagIds)
      .then(({ data }) => {
        onOrderUpdated(data);
      })
      .catch((reason) => {
        notification.error({ duration: 0, type: 'error', message: getErrorFromPromiseReason(reason) });
      })
      .finally(() => {
        setIsLoading(false);
        finalCallback();
      });
  };

  return { isLoading, updateOrder };
};

export default useUpdateOrder;
