import { Org, CreateUpdateOrgData } from 'api/Orgs/types';
import { useCreateOrg, useUpdateOrg } from 'components/Dashboard/Orgs/Wizard/OrgForm/hooks';

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Form, Input, Button, Checkbox, Typography, Slider, FloatButton } from 'antd';
import type { SliderSingleProps } from 'antd';
import React, { useEffect } from 'react';

interface Props {
  org?: Org;
}

const OrgForm: React.FC<Props> = ({ org }) => {
  const [ form ] = Form.useForm<CreateUpdateOrgData>();
  Form.useWatch('useOwnBatchQueue', form);
  const resultSummaryGroupSet = Form.useWatch('resultSummaryGroupSet', form);
  const resultSummaryChroms = Form.useWatch('resultSummaryChroms', form);
  const quickbooksEnabled = Form.useWatch('quickbooksEnabled', form);
  const { createOrg, isCreating } = useCreateOrg();
  const { updateOrg, isUpdating } = useUpdateOrg();

  const onFinish = (values: CreateUpdateOrgData) => {
    if (org) {
      updateOrg(org.id, values);
    } else {
      createOrg(values);
    }
  };

  useEffect(() => {
    if (resultSummaryGroupSet === false) {
      form.setFieldValue('resultDetailedGroupSet', false);
      form.setFieldValue('reportPrsEnabled', false);
      form.setFieldValue('reportPrsClinicalEnabled', false);
    }
  }, [ resultSummaryGroupSet, form ]);

  useEffect(() => {
    if (resultSummaryChroms === false) {
      form.setFieldValue('resultDetailedChroms', false);
      form.setFieldValue('reportPdfEnabled', false);
    }
  }, [ resultSummaryChroms, form ]);

  const marks: SliderSingleProps['marks'] = { 256: '256', 8192: '8192' };

  return (
    <Form
      form={form}
      name="org"
      style={{ width: 480 }}
      layout="vertical"
      onFinish={onFinish}
      autoComplete="off"
      requiredMark={false}
      initialValues={{
        name: org?.name,
        homeFolderName: org?.homeFolderName,
        domains: org?.domains,
        mfaEnabled: org?.mfaEnabled,
        resultSummaryGroupSet: !!org?.resultSummaryGroupSet,
        resultSummaryChroms: !!org?.resultSummaryChroms,
        resultDetailedGroupSet: org?.resultDetailedGroupSet,
        resultDetailedChroms: org?.resultDetailedChroms,
        reportPdfEnabled: org?.reportPdfEnabled,
        reportPrsEnabled: org?.reportPrsEnabled,
        reportPrsClinicalEnabled: org?.reportPrsClinicalEnabled,
        useOwnBatchQueue: org?.useOwnBatchQueue,
        batchMaxVcpu: org?.batchMaxVcpu || 1024,
        batchJobQueue: org?.batchJobQueue,
        quickbooksEnabled: !!org?.quickbooksEnabled,
        quickbooksCustomerId: org?.quickbooksCustomerId,
        quickbooksEmail: org?.quickbooksEmail,
        webhooksEnabled: !!org?.webhooksEnabled,
      }}
      disabled={isCreating || isUpdating}
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[ { required: true, message: 'Please enter organization name' } ]}
      >
        <Input placeholder="Enter name" />
      </Form.Item>

      <Form.Item
        label="Home folder name"
        name="homeFolderName"
        tooltip={org ? null : {
          title: 'Home folder name will be auto generated',
          styles: { root: { maxWidth: 300 } },
        }}
      >
        <Input disabled />
      </Form.Item>

      <Form.Item name="mfaEnabled" valuePropName="checked" style={{ marginBottom: 0 }}>
        <Checkbox>Multi-factor authentication</Checkbox>
      </Form.Item>

      <Form.Item name="webhooksEnabled" valuePropName="checked">
        <Checkbox>Webhooks</Checkbox>
      </Form.Item>

      <Typography.Title level={5}>Results</Typography.Title>

      <Form.Item name="resultSummaryChroms" valuePropName="checked" style={{ marginBottom: 0 }}>
        <Checkbox>Summary Sub set</Checkbox>
      </Form.Item>

      <Form.Item name="resultDetailedChroms" valuePropName="checked" style={{ margin: '0 0 0 32px' }}>
        <Checkbox disabled={!form.getFieldValue('resultSummaryChroms')}>Detailed Sub set by chromosomes</Checkbox>
      </Form.Item>

      <Form.Item name="reportPdfEnabled" valuePropName="checked" style={{ margin: '0 0 0 32px' }}>
        <Checkbox disabled={!form.getFieldValue('resultSummaryChroms')}>PDF Ancestry report</Checkbox>
      </Form.Item>

      <Form.Item name="resultSummaryGroupSet" valuePropName="checked" style={{ marginBottom: 0 }}>
        <Checkbox>Summary Super set</Checkbox>
      </Form.Item>

      <Form.Item name="resultDetailedGroupSet" valuePropName="checked" style={{ margin: '0 0 0 32px' }}>
        <Checkbox disabled={!form.getFieldValue('resultSummaryGroupSet')}>Detailed Super set by chromosomes</Checkbox>
      </Form.Item>

      <Form.Item name="reportPrsEnabled" valuePropName="checked" style={{ margin: '0 0 0 32px' }}>
        <Checkbox disabled={!form.getFieldValue('resultSummaryGroupSet')}>PDF PRS report</Checkbox>
      </Form.Item>

      <Form.Item name="reportPrsClinicalEnabled" valuePropName="checked" style={{ margin: '0 0 24px 32px' }}>
        <Checkbox disabled={!form.getFieldValue('resultSummaryGroupSet')}>PDF PRS Clinical report</Checkbox>
      </Form.Item>

      <Typography.Title level={5}>Batch queue</Typography.Title>

      <Form.Item name="useOwnBatchQueue" valuePropName="checked">
        <Checkbox>Use own batch queue</Checkbox>
      </Form.Item>

      <Form.Item name="batchMaxVcpu" style={{ marginBottom: 12 }} label="Max vCPU">
        <Slider
          min={256}
          max={8192}
          step={256}
          marks={marks}
          disabled={!form.getFieldValue('useOwnBatchQueue')}
          className="cpuSlider"
        />
      </Form.Item>

      {org && (
        <Form.Item label="Batch job queue" name="batchJobQueue">
          <Input disabled />
        </Form.Item>
      )}

      <Typography.Title level={5}>Quickbooks</Typography.Title>

      <Form.Item name="quickbooksEnabled" valuePropName="checked" style={{ marginBottom: 8 }}>
        <Checkbox>Automatic invoice</Checkbox>
      </Form.Item>

      <Form.Item
        label="Accounting email"
        name="quickbooksEmail"
        rules={[ { required: quickbooksEnabled, message: 'Please enter accounting email' }, { type: 'email', message: 'Please enter valid email' } ]}
      >
        <Input placeholder="Enter accounting email" disabled={!quickbooksEnabled} />
      </Form.Item>

      <Form.Item
        label="Customer ID"
        name="quickbooksCustomerId"
        rules={[ { required: quickbooksEnabled, message: 'Please enter customer ID' } ]}
      >
        <Input placeholder="Enter customer ID" disabled={!quickbooksEnabled} />
      </Form.Item>

      <Typography.Title level={5}>Domains</Typography.Title>

      <Form.List name="domains">
        {(fields, { add, remove }) => (
          <>
            {fields.map(field => (
              <Form.Item key={field.key}>
                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                  <Form.Item {...field} rules={[ { required: true, message: 'Enter domain' } ]} style={{ marginBottom: 0, width: '100%' }}>
                    <Input placeholder="Domain" data-testid={`domains_${field.key}`} />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(field.name)} style={{ marginLeft: 8 }} />
                </div>
              </Form.Item>
            ))}

            <Form.Item>
              <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                Add domain
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>

      <FloatButton
        htmlType="submit"
        description={<div style={{ fontSize: 14 }}>{org ? 'Update' : 'Create'}</div>}
        shape="square"
        type="primary"
        style={{
          insetInlineStart: 832,
          width: 78,
        }}
      />
    </Form>
  );
};

export default OrgForm;
