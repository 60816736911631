import { uploadFileRequest } from 'api/Data/api';
import { File } from 'api/Data/types';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { useState } from 'react';
import { notification } from 'antd';
import type { RcFile } from 'antd/es/upload/interface';

export const useFileUpload = (onFileUploaded: (file: File) => void) => {
  const [ isUploading, setIsUploading ] = useState<boolean>(false);

  const uploadFile = (file: RcFile) => {
    setIsUploading(true);
    const data = new FormData();
    data.append('file', file);

    uploadFileRequest(data)
      .then((response) => {
        onFileUploaded(response.data);
      })
      .catch((reason) => {
        notification.error({ duration: 0, type: 'error', message: getErrorFromPromiseReason(reason) });
      })
      .finally(() => {
        setIsUploading(false);
      });
  };

  return { isUploading, uploadFile };
};
