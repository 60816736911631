import { generateOrgWebhooksSecretRequest } from 'api/Orgs/api';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';
import { useAppDispatch } from 'store/hooks';
import { changeOrgWebhooksSecret } from 'store/orgs/orgsSlice';
import { changeUserOrgWebhooksSecret } from 'store/users/usersSlice';

import { message, notification } from 'antd';
import { useCallback, useState } from 'react';

const useOrgWebhooksSecret = () => {
  const dispatch = useAppDispatch();
  const [ isLoading, setIsLoading ] = useState<boolean>(false);

  const generateSecret = useCallback((orgId: string) => {
    setIsLoading(true);
    generateOrgWebhooksSecretRequest(orgId)
      .then(({ data }) => {
        dispatch(changeOrgWebhooksSecret({ orgId, value: data }));
        dispatch(changeUserOrgWebhooksSecret({ orgId, value: data }));
        message.success('Secret key was updated');
      })
      .catch((reason) => {
        notification.error({ duration: 0, type: 'error', message: getErrorFromPromiseReason(reason) });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [ dispatch ]);

  return { isLoading, generateSecret };
};

export default useOrgWebhooksSecret;
