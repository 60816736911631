import { uploadResultsRequest } from 'api/Data/api';
import { ModelApiName } from 'api/Exec/types';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { useState } from 'react';
import { message, notification } from 'antd';
import type { RcFile } from 'antd/es/upload/interface';

const useUploadResults = (onUploaded: () => void) => {
  const [ isUploading, setIsUploading ] = useState<boolean>(false);

  const uploadResults = (file: RcFile, modelApiName: ModelApiName, tagIds: string[]) => {
    setIsUploading(true);
    const data = new FormData();
    data.append('file', file);
    data.append('model_api_name', modelApiName);
    data.append('tags_ids', tagIds.join(','));

    uploadResultsRequest(data)
      .then(() => {
        onUploaded();
        message.success('Results were uploaded');
      })
      .catch((reason) => {
        notification.error({ duration: 0, type: 'error', message: getErrorFromPromiseReason(reason) });
      })
      .finally(() => {
        setIsUploading(false);
      });
  };

  return { isUploading, uploadResults };
};

export default useUploadResults;
