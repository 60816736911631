import { DashboardPageProps } from 'components/Dashboard/types';
import WebUsers from 'components/Dashboard/Users/WebUsers/WebUsers';

import React, { useEffect } from 'react';

const Users: React.FC<DashboardPageProps> = ({ setTitle }) => {
  useEffect(() => {
    setTitle('Users');
  }, [ setTitle ]);

  return <WebUsers />;
};

export default Users;
