import { updateOrgRequest } from 'api/Orgs/api';
import { CreateUpdateOrgData } from 'api/Orgs/types';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';
import { changeOrg } from 'store/orgs/orgsSlice';
import { useAppDispatch } from 'store/hooks';

import { message, notification } from 'antd';
import { useState } from 'react';

const useUpdateOrg = () => {
  const dispatch = useAppDispatch();
  const [ isUpdating, setIsUpdating ] = useState<boolean>(false);

  const updateOrg = (orgId: string, data: CreateUpdateOrgData) => {
    setIsUpdating(true);

    updateOrgRequest(orgId, data)
      .then(({ data }) => {
        dispatch(changeOrg(data));
        message.success('Organization was updated');
      })
      .catch((reason) => {
        notification.error({ duration: 0, type: 'error', message: getErrorFromPromiseReason(reason) });
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  return { isUpdating, updateOrg };
};

export default useUpdateOrg;
