import { downloadFileRequest } from 'api/Data/api';
import { File } from 'api/Data/types';
import { Role } from 'api/Users/types';
import { dashboardUrl, ordersUrl } from 'app/consts/urls';
import { formatDateTime } from 'app/helpers/dateTime';
import { getFileFromAxiosBlobResponse } from 'app/helpers/downloadFile';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';
import { pluralize } from 'app/helpers/pluralize';
import { useAppSelector } from 'store/hooks';
import { selectMyUser } from 'store/users/usersSlice';

import React, { useState } from 'react';
import { ColumnsType, ColumnType } from 'antd/es/table';
import { ItemType } from 'antd/es/menu/interface';
import { Button, Dropdown, notification, Space, Tag, Tooltip, Typography } from 'antd';
import { EllipsisOutlined, LoadingOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const useColumns = (
  onCreateAncestryCall: (fileId: string) => void,
  onDeleteClick: (fileId: string) => void,
) => {
  const [ downloadingFileId, setDownloadingFileId ] = useState<string | null>(null);
  const { role: myUserRole } = useAppSelector(selectMyUser);
  const navigate = useNavigate();
  const goToOrders = (file: string) => navigate(`/${dashboardUrl}/${ordersUrl}?search=${file}`, { replace: true });

  const downloadFile = (fileId: string) => {
    setDownloadingFileId(fileId);

    downloadFileRequest(fileId)
      .then((response) => {
        getFileFromAxiosBlobResponse(response);
      })
      .catch((reason) => {
        notification.error({ duration: 0, type: 'error', message: getErrorFromPromiseReason(reason) });
      })
      .finally(() => {
        setDownloadingFileId(null);
      });
  };

  const columns: ColumnsType<File> = [
    {
      title: 'File name',
      render: (_, { deletedAt, srcShortFilePath }) => {
        if (!deletedAt) {
          return srcShortFilePath;
        }

        return (
          <>
            <Typography.Text type="secondary">{srcShortFilePath}</Typography.Text>
            <br />
            <Typography.Text type="secondary" style={{ fontSize: 12 }}>Deleted {formatDateTime(deletedAt)}</Typography.Text>
          </>
        );
      },
    },
    {
      title: 'Uploaded',
      render: (_, { deletedAt, createdAt }) => {
        return <Typography.Text type={deletedAt ? 'secondary' : undefined}>{formatDateTime(createdAt)}</Typography.Text>;
      },
    },
    {
      title: 'Type',
      render: (_, { deletedAt, genomeType, virtual }) => {
        return (
          <Typography.Text type={deletedAt ? 'secondary' : undefined} style={{ wordBreak: 'normal' }}>
            {virtual ? 'EXTERNAL' : genomeType}
          </Typography.Text>
        );
      },
    },
    {
      title: 'Samples',
      render: (_, { deletedAt, amountOfSamples }) => {
        return <Typography.Text type={deletedAt ? 'secondary' : undefined}>{amountOfSamples}</Typography.Text>;
      },
    },
    ...(myUserRole === Role.SYSTEM_ADMIN ? [ {
      title: 'Organization',
      render: (_, { deletedAt, orgName }) => {
        return <Typography.Text type={deletedAt ? 'secondary' : undefined}>{orgName}</Typography.Text>;
      },
    } as ColumnType<File> ] : []),
    {
      title: 'Status',
      render: (_, { acceptable, checkCompleted, checkInformation }) => {
        if (!checkCompleted) {
          return <Tag color="geekblue">Pending</Tag>;
        } else if (!acceptable) {
          return (
            <Space direction="vertical">
              <Tag color="red">Invalid</Tag>
              <Tooltip title={checkInformation} styles={{ root: { maxWidth: 600 } }}>
                <Typography.Paragraph ellipsis style={{ fontSize: 12, whiteSpace: 'nowrap', maxWidth: 200, marginBottom: 0 }}>
                  {checkInformation}
                </Typography.Paragraph>
              </Tooltip>
            </Space>
          );
        } else {
          return <Tag color="purple">Valid</Tag>;
        }
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, file) => {
        const { id, ordersCount, acceptable, deletedAt, checkCompleted } = file;
        const disabled = !!deletedAt || !acceptable || !checkCompleted;

        return ordersCount === 0 ?
          <Button type="link" style={{ padding: 0 }} onClick={() => onCreateAncestryCall(id)} disabled={disabled}>Create Ancestry Call</Button> :
          <Button type="link" style={{ padding: 0 }} onClick={() => goToOrders(id)}>View {pluralize(ordersCount, 'order')}</Button>;
      },
    },
    {
      align: 'right',
      title: '',
      key: 'moreActions',
      render: (_, file) => {
        const items: ItemType[] = [
          { key: 'downloadFile', label: 'Download source file', onClick: () => downloadFile(file.id), disabled: file.id === downloadingFileId },
          { key: 'deleteFile', label: 'Delete', onClick: () => onDeleteClick(file.id) },
        ];

        if (file.ordersCount > 0 && file.acceptable) {
          items.unshift({ key: 'createAncestryCall', label: 'Create Ancestry Call', onClick: () => onCreateAncestryCall(file.id) });
        }

        return (
          <Dropdown trigger={[ 'click' ]} menu={{ items }} disabled={!!file.deletedAt}>
            {file.id === downloadingFileId ? <LoadingOutlined style={{ fontSize: 24 }} /> : <EllipsisOutlined style={{ fontSize: 24 }} />}
          </Dropdown>
        );
      },
    },
  ];

  return { columns };
};

export default useColumns;
