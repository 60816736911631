import { SelectOption } from 'app/types/Forms';
import { Model, DockerImage, NamedModel, ModelApiName } from 'api/Exec/types';
import { useDockerImages, useAddDockerImage } from 'components/Dashboard/Models/DockerImageForm/hooks';
import { selectModelsApi } from 'store/exec/execSlice';
import { useAppSelector } from 'store/hooks';

import React, { useEffect, useState } from 'react';
import { Button, Form, Select, Space } from 'antd';

interface FormValues {
  image: string;
  modelApi: ModelApiName;
  modelName: string | undefined;
}

interface Props {
  namedModels: NamedModel[];
  onCancel: () => void;
  onDockerImageAdded: (model: Model) => void;
}

const DockerImageForm: React.FC<Props> = ({ namedModels, onCancel, onDockerImageAdded }) => {
  const [ form ] = Form.useForm<FormValues>();
  const modelApi = Form.useWatch('modelApi', form);
  const modelsApi = useAppSelector(selectModelsApi);
  const { addDockerImage, isCreating } = useAddDockerImage();
  const { dockerImages, isLoading } = useDockerImages();
  const [ dockerImagesOptions, setDockerImagesOptions ] = useState<SelectOption[]>([]);
  const [ namedModelsOptions, setNamedModelsOptions ] = useState<SelectOption[]>([]);

  useEffect(() => {
    const options: SelectOption[] = dockerImages.map((dockerImage: DockerImage) => ({
      label: dockerImage.imageTag,
      value: dockerImage.imageTag,
    }));
    setDockerImagesOptions(options);
  }, [ dockerImages ]);

  useEffect(() => {
    const options: SelectOption[] = namedModels
      .filter((namedModel: NamedModel) => namedModel.modelApiName === modelApi && !namedModel.deprecated)
      .map((namedModel: NamedModel) => ({
        label: namedModel.modelName,
        value: namedModel.modelName,
      }));
    form.resetFields([ 'modelName' ]);
    setNamedModelsOptions(options);
  }, [ namedModels, modelApi, form ]);

  const onFinish = async (values: FormValues) => {
    const dockerImage = await addDockerImage(values.image, values.modelApi, values.modelName);
    if (dockerImage) {
      onDockerImageAdded(dockerImage);
      form.resetFields();
    }
  };

  return (
    <Form
      form={form}
      name="dockerImage"
      style={{ width: 360 }}
      layout="vertical"
      onFinish={onFinish}
      disabled={isCreating || isLoading}
    >
      <Form.Item
        name="image"
        label="Image"
        rules={[ { required: true, message: 'Field is mandatory' } ]}
      >
        <Select placeholder="Select image" options={dockerImagesOptions} loading={isLoading} />
      </Form.Item>

      <Form.Item
        name="modelApi"
        label="Model API"
        rules={[ { required: true, message: 'Field is mandatory' } ]}
      >
        <Select placeholder="Select model API" options={modelsApi} loading={isLoading} />
      </Form.Item>

      <Form.Item
        name="modelName"
        label="Named model for update"
        tooltip={{
          title: 'Select a named model from the list to update and publish it',
          styles: { root: { maxWidth: 500 } },
        }}
      >
        <Select placeholder="Select model name" options={namedModelsOptions} loading={isLoading} allowClear disabled={!modelApi || isCreating} />
      </Form.Item>

      <Form.Item style={{ textAlign: 'right', marginBottom: 0 }}>
        <Space>
          <Button onClick={onCancel}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Add docker image
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default DockerImageForm;
